// contexts/UserContext.js
import React, { createContext, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    avatar: localStorage.getItem('avatar') || 'avatar1',
    user: ''
  });

  const updateUserContext = (newData) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...newData,
    }));

    if (newData.avatar) {
      localStorage.setItem('avatar', newData.avatar);
      localStorage.setItem('name', newData.name);
    }
  };

  return (
    <UserContext.Provider value={{ user, updateUserContext }}>
      {children}
    </UserContext.Provider>
  );
};