import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Spinner, Button, Table, ProgressBar, Modal, Form, Row, Col, Card, InputGroup, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTag, faPlus, faSquareCheck, faMoneyBillTransfer, faBox, faMoneyBillWave, faCoins, faTrash, faTruck, faEuroSign, faCalculator, faList, faMoneyCheckDollar, faCalendarDay, faCheck, faChevronRight, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FaPlus, FaEye, FaRectangleAd, FaUsers, FaEdit, FaTrash, FaDollarSign, FaTools } from 'react-icons/fa';

import axios from '../utils/axios';
import './Simulations.css';
import './Stepper.css'
import Slider from 'rc-slider';
import { useTranslation } from 'react-i18next';

const wrapLTR = (text) => <span dir="ltr">{text}</span>;

const Simulations = () => {
  const { t } = useTranslation();
  const [simulations, setSimulations] = useState([]);
  const [feeVariations, setFeeVariations] = useState(
    []
  );
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [simulationToDelete, setSimulationToDelete] = useState(null);
  const [newSimulation, setNewSimulation] = useState({
    name: '',
    description: '',
    product_price: '',
    sale_price: '',
    euro_price: '',
    daily_ads_spend: '',
    cost: '',
    orders: '',
    delivery_price: '',
    confirmation_percentage: 0,
    delivery_percentage: 0,
    cod_fee: '',
    return_fee: '',    
    packaging_fee: '',
  });

  const [selectedSimulation, setSelectedSimulation] = useState(null);
  const [activeTab, setActiveTab] = useState('order');
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [enrichedEmployees, setEnrichedEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const steps = [1, 2, 3];

  const handlenewSimulation = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleSliderChange = (value, name) => {
    const updatednewSimulation = { ...newSimulation, [name]: value };
    setNewSimulation(updatednewSimulation);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Row>
              <Form.Group controlId="name" className="form-group-custom">
                <Form.Label> {t('SIMULATION.NAME')} </Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="name"
                    value={newSimulation.name}
                    onChange={handleChange}
                    className="form-control-custom"
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="description" className="form-group-custom">
                <Form.Label> {t('SIMULATION.DESCRIPTION')} </Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="description"
                    as="textarea"
                    value={newSimulation.description}
                    onChange={handleChange}
                    className="form-control-custom"
                  />
                </InputGroup>
              </Form.Group>
            </Row>
          </>
        );
      case 2:
        return (
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="productPrice" className="form-group-custom">
                <Form.Label> <FontAwesomeIcon icon={faTag} /> {t('SIMULATION.PRODUCT_PRICE')} </Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    name="product_price"
                    value={newSimulation.product_price}
                    onChange={handleChange}
                    className="form-control-custom"
                  />
                  <InputGroup.Text>DZD</InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="salePrice" className="form-group-custom">
                <Form.Label> <FontAwesomeIcon icon={faCoins} /> {t('SIMULATION.SALE_PRICE')} </Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    name="sale_price"
                    value={newSimulation.sale_price}
                    onChange={handleChange}
                    className="form-control-custom"
                  />
                  <InputGroup.Text>DZD</InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="cost" className="form-group-custom">
              <Form.Label> <FontAwesomeIcon icon={faMoneyCheckDollar}/> {t('SIMULATION.COST')}</Form.Label>
              <InputGroup>
                  <Form.Control
                    type="number"
                    name="cost"
                    value={newSimulation.cost}
                    onChange={handleChange}
                    className="form-control-custom"
                  />
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="euroPrice" className="form-group-custom">
                <Form.Label> <FontAwesomeIcon icon={faDollarSign} /> {t('SIMULATION.DOLLAR_PRICE')}</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    name="euro_price"
                    value={newSimulation.euro_price}
                    onChange={handleChange}
                    className="form-control-custom"
                  />
                  <InputGroup.Text>DZD</InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="dailyAdsSpend" className="form-group-custom">
                <Form.Label> <FontAwesomeIcon icon={faCalendarDay} /> {t('SIMULATION.DOLLAR_SPEND_PER_DAY')} </Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    name="daily_ads_spend"
                    value={newSimulation.daily_ads_spend}
                    onChange={handleChange}
                    className="form-control-custom"
                  />
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        );
      case 3:
        return (
          <>
            <Row>
              <Col xs={12} md={6}>

                <Form.Group controlId="delivery_price" className="form-group-custom">
                  <Form.Label><FontAwesomeIcon icon={faMoneyBillTransfer} /> {t('SIMULATION.DELIVERY_PRICE')}</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="delivery_price"
                      value={newSimulation.delivery_price}
                      onChange={handleChange}
                      className="form-control-custom"
                    />
                    <InputGroup.Text>DZD</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="packaging_fee" className="form-group-custom">
                          <Form.Label><FontAwesomeIcon icon={faMoneyBillTransfer} /> {t('SIMULATION.PACKAGING_FEE')} </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="packaging_fee"
                              value={newSimulation.packaging_fee}
                              onChange={handleChange}
                              className="form-control-custom"
                            />
                            <InputGroup.Text>DZD</InputGroup.Text>
                          </InputGroup>
                        </Form.Group>


                <Form.Group controlId="confirmationPercentage" className="form-group-custom">
                  <Form.Label><FontAwesomeIcon icon={faSquareCheck} /> {t('SIMULATION.CONFIRMATION_PERCENTAGE')}</Form.Label>
                  <Slider
                    min={0}
                    max={100}
                    value={newSimulation.confirmation_percentage || 0}
                    onChange={(value) => handleSliderChange(value, 'confirmation_percentage')}
                    className="slider-custom"
                  />
                  <Form.Text className="form-text-custom">{newSimulation.confirmation_percentage}%</Form.Text>
                </Form.Group>


                <Form.Group controlId="deliveryPercentage" className="form-group-custom">
                  <Form.Label><FontAwesomeIcon icon={faTruck} /> {t('SIMULATION.DELIVERY_PERCENTAGE')}</Form.Label>
                  <Slider
                    min={0}
                    max={100}
                    value={newSimulation.delivery_percentage || 0}
                    onChange={(value) => handleSliderChange(value, 'delivery_percentage')}
                    className="slider-custom"
                  />
                  <Form.Text className="form-text-custom">{newSimulation.delivery_percentage}%</Form.Text>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="codFee" className="form-group-custom">
                  <Form.Label><FontAwesomeIcon icon={faMoneyBillWave} /> { t('SIMULATION.COD_FEE') }</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="cod_fee"
                      value={newSimulation.cod_fee}
                      onChange={handleChange}
                      className="form-control-custom"
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="returnFee" className="form-group-custom">
                  <Form.Label><FontAwesomeIcon icon={faBox} /> { t('SIMULATION.RETURN_FEE') }</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="return_fee"
                      value={newSimulation.return_fee}
                      onChange={handleChange}
                      className="form-control-custom"
                    />
                    <InputGroup.Text>DZD</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>

          </>
        );
      default:
        return null;
    }
  };

  const formatNumberWithCurrency = (num, currency = 'DZD') => {
    if (isNaN(num) || num === null) return `0.00 ${currency}`;
    return `${num
      .toFixed(2) // Ensure two decimal places
      .replace(/\d(?=(\d{3})+\.)/g, '$& ') // Add thousands separator
      .replace(/\.00$/, '')} ${currency}`; // Remove decimal places if zero
  };

  const formatNumber = (num) => {
    // Ensure num is a valid number, otherwise return '0.00'
    if (isNaN(num)) return '0.00';

    // Format the number to show up to two decimal places only when needed
    const formattedNum = parseFloat(num).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    });

    return formattedNum;
  };

  useEffect(() => {
    const fetchSimulations = async () => {
      try {
        const response = await axios.get('/simulations', { withCredentials: true });
        setSimulations(response.data);
        if (response.data.length > 0) {
          setSelectedSimulation(response.data[0]);
          console.log(response.data[0]);
        }
      } catch (error) {
        console.error('Error fetching simulations', error);
      } finally {
        setLoading(false);
      }        
    };

    const fetchFeeVariations = async () => {
      try {
        const response = await axios.get('/fee-variations', { withCredentials: true });
        setFeeVariations(response.data);
      } catch (error) {
        console.error('Error fetching fee variations data', error);
      }
    };

    fetchSimulations();
    fetchFeeVariations();
  }, []);

  const handleAddSimulation = async () => {

    try {
      const response = await axios.post('/simulations', newSimulation, { withCredentials: true });
      setSimulations([...simulations, response.data]);
      setShowModal(false);
      navigate(`/dashboard/simulations/${response.data.id}`);
    } catch (error) {
      console.error('Error adding simulation', error);
    }
  };

  const handleChange = (e) => {
    setNewSimulation({ ...newSimulation, [e.target.name]: e.target.value });
  };

  const handleConfirmDelete = (simulationId) => {
    setSimulationToDelete(simulationId);
    setShowConfirmModal(true);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`/simulations/${simulationToDelete}`, { withCredentials: true });
      setSimulations(simulations.filter((sim) => sim.id !== simulationToDelete));
      setShowConfirmModal(false);
    } catch (error) {
      console.error('Error deleting simulation', error);
    }
  };

  const handleSelectSimulation = (simulation) => {
    setSelectedSimulation(simulation);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const netProfitClass = selectedSimulation?.net_profit >= 0 ? 'positive' : 'negative';
  const netProfitClassDaily = selectedSimulation?.net_profit_daily >= 0 ? 'positive' : 'negative';
  const netProfitClassMonthly = selectedSimulation?.net_profit_monthly >= 0 ? 'positive' : 'negative';

  const feeVariation = feeVariations?.find(variation => variation.id === selectedSimulation?.fee_variation_id) || {};
  const employeeSalaries = feeVariation.EmployeeSalaries || [];

  useEffect(() => {
    const enrichEmployees = () => {
      if (!selectedSimulation) return [];

      const feeVariation = feeVariations?.find(variation => variation.id === selectedSimulation.fee_variation_id) || {};
      const employeeSalaries = feeVariation.EmployeeSalaries || [];
      const enrichedEmployees = Array.isArray(selectedSimulation.selected_employees)
        ? selectedSimulation.selected_employees.map(employee => {
          // Find employee details in employeeSalaries
          const employeeDetails = employeeSalaries.find(emp => emp.id === parseInt(employee.employeeId)) || {};
          // Merge employee with employeeDetails
          return {
            ...employee,
            name: employeeDetails.name,
            variable_amount: employeeDetails.variable_amount || 0, // Provide default amount
            per_order_basis: employeeDetails.per_order_basis || 'N/A', // Provide default basis
            role: employee.role // Ensure we include the role
          };
        })
        : []; // Default to an empty array if selected_employees is not an array

      setEnrichedEmployees(enrichedEmployees);
    };

    enrichEmployees();
  }, [selectedSimulation, feeVariations]);

 const renderTable = () => {

  if (activeTab === 'order') {
    return (
      <>
        <Table responsive bordered striped hover className="custom-table mb-4">
          <thead>
            <tr>
            <th className='th-label-simulation'>{t('SIMULATION.LABEL')}</th>
            <th>{t('SIMULATION.PRICE')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='td-label'>{t('SIMULATION.PRODUCT_PRICE')}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.product_price, 'DZD'))}</td>
            </tr>
            <tr>
              <td className='td-label'>{t('SIMULATION.SALE_PRICE')}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.sale_price, 'DZD'))}</td>
            </tr>
            <tr>
              <td className='td-label'>{t('SIMULATION.DELIVERY_PRICE')}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.delivery_price, 'DZD'))}</td>
            </tr>
            <tr>
              <td className='td-label'>{t('SIMULATION.PACKAGING_FEE')}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.packaging_fee, 'DZD'))}</td>
            </tr>
            <tr>
              <td className='td-label'>{t('SIMULATION.CONFIRMATION_COST_PER_ORDER')}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.confirmation_cost_per_order, 'DZD'))}</td>
            </tr>
            <tr>
              <td className='td-label'>{t('SIMULATION.DELIVERY_COST_PER_ORDER')}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.delivery_cost_per_order, 'DZD'))}</td>
            </tr>
            <tr>
              <td className='td-label'>{t('SIMULATION.RETURN_FEE_PER_ORDER')}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.return_fee_per_order, 'DZD'))}</td>
            </tr>
            <tr>
              <td className='td-label'>{t('SIMULATION.COD_FEE_PER_ORDER')}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.cod_fee_per_order, 'DZD'))}</td>
            </tr>
            <tr>
              <td className='td-label'>{t('SIMULATION.OTHER_FEE_PER_ORDER')}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.other_fee_per_order, 'DZD'))}</td>
            </tr>
            <tr>
              <td className='td-label'>{t('SIMULATION.FEE_ROLE_PER_ORDER')}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.fee_role_per_order, 'DZD'))}</td>
            </tr>
          </tbody>
        </Table>

        <Table responsive bordered striped hover className="employee-variant-table custom-table mb-4">
          <thead>
            <tr>
              <th>{t('SIMULATION.ROLE')}</th>
              <th>{t('SIMULATION.EMPLOYEE_NAME')}</th>
              <th>{t('SIMULATION.VARIABLE_AMOUNT')}</th>
              <th>{t('SIMULATION.PER_ORDER')}</th>
            </tr>
          </thead>
          <tbody>
            {enrichedEmployees.map((emp, index) => (
              <tr key={index}>
                <td>{emp.role}</td>
                <td>{emp.name}</td>
                <td>{emp.variable_amount ? wrapLTR(`${formatNumberWithCurrency(emp.variable_amount, 'DZD')}`) : 'N/A'}</td>
                <td>{wrapLTR(t(`SIMULATION.PER_ORDER_BASIS_${emp.per_order_basis}`))}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="footer">
          <span className="total-profit">{t('SIMULATION.NET_PROFIT')}</span>
          <span className={`total-amount-${netProfitClass}`}>{wrapLTR(formatNumberWithCurrency(selectedSimulation.net_profit))}</span>
        </div>
        <div className="footer">
          <span className="total-profit">{t('SIMULATION.ROI')}</span>
          <span className={`total-amount-${netProfitClass}`}>{wrapLTR(formatNumberWithCurrency(selectedSimulation.roi_per_order, '%'))}</span>
        </div>
        <div className="footer">
          <span className="total-profit">{t('SIMULATION.ROAS')}</span>
          <span className={`total-amount-${netProfitClass}`}>{wrapLTR(formatNumberWithCurrency(selectedSimulation.roas_per_order, ''))}</span>
        </div>
      </>
    );
  } else if (activeTab === 'time') {
    return (
      <>        
        <Table responsive bordered striped hover className="custom-table mb-4">
          <thead>
            <tr>
            <th className='th-label-simulation'>{t('SIMULATION.LABEL')}</th>
            <th>{t('SIMULATION.DAILY')}</th>
              <th>{t('SIMULATION.MONTHLY')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='td-label'>{t('SIMULATION.ADS_SPEND')}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.daily_ads_spend, '$'))}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.monthly_ads_spend, '$'))}</td>
            </tr>
            <tr>
              <td className='td-label'>{t('SIMULATION.CONFIRMED_ORDERS')}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.confirmed_orders_daily, 'Orders'))}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.confirmed_orders_monthly, 'Orders'))}</td>
            </tr>
            <tr>
              <td className='td-label'>{t('SIMULATION.DELIVERED_ORDERS')}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.delivered_orders_daily, 'Orders'))}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.delivered_orders_monthly, 'Orders'))}</td>
            </tr>
            <tr>
              <td className='td-label'>{t('SIMULATION.RETURN_FEE')}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.return_fee_daily))}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.return_fee_monthly))}</td>
            </tr>
            <tr>
              <td className='td-label'>{t('SIMULATION.COD_FEE')}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.cod_fee_daily))}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.cod_fee_monthly))}</td>
            </tr>
            <tr>
              <td className='td-label'>{t('SIMULATION.TOOLS_FEE')}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.tools_fee_daily))}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.tools_fee_monthly))}</td>
            </tr>
            <tr>
              <td className='td-label'>{t('SIMULATION.FIXED_FEE')}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.fixed_fee_daily))}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.fixed_fee_monthly))}</td>
            </tr>
            <tr>
              <td className='td-label'>{t('SIMULATION.EMPLOYEE_FEE')}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.employee_salary_daily))}</td>
              <td className="table-amount">{wrapLTR(formatNumberWithCurrency(selectedSimulation?.employee_salary_monthly))}</td>
            </tr>
          </tbody>
        </Table>
        <div className="footer-table">
          <Table responsive borderless hover className="custom-table mb-4">
            <tbody>
              <tr>
                <td className="td-label total-profit" style={{ width: '200px' }}>{t('SIMULATION.NET_PROFIT')}</td>
                <td className={`total-amount-${netProfitClassDaily}`}>{wrapLTR(formatNumberWithCurrency(selectedSimulation?.net_profit_daily ?? 0))}</td>
                <td className={`total-amount-${netProfitClassMonthly}`}>{wrapLTR(formatNumberWithCurrency(selectedSimulation?.net_profit_monthly ?? 0))}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </>
    );
  }
};

  const renderPreview = () => {
    if (!selectedSimulation) {
      return (
        <Card>
          <Card.Body> {t('SIMULATION.Select a simulation to preview details here')} </Card.Body>
        </Card>
      );
    }

    return (
      <>
        <Card className="mb-4 preview">
          <Card.Body>
            <h3>{selectedSimulation.name}</h3>
            <div className="scroll-container mb-3">
              <Row className="flex-nowrap">
                <Col className="scroll-item">
                  <Card className="sum-card mb-2 simulation-gradient-card">
                    <Card.Body>
                      <Card.Text className="text">
                        <p> <span className={`total-amount-${netProfitClass}`}>
                          {formatNumberWithCurrency(selectedSimulation?.net_profit ?? 0)}
                        </span></p>
                        <div className='text'>{t('SIMULATION.NET_PROFIT')} </div>
                        <div className="fee-overview d-flex flex-wrap">
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="scroll-item">
                  <Card className="sum-card mb-2 simulation-gradient-card">
                    <Card.Body>
                      <Card.Text className="text">
                        <p> <span className={`total-amount-${netProfitClassDaily}`}>
                          {formatNumberWithCurrency(selectedSimulation?.net_profit_daily ?? 0)} / 
                          {t('SIMULATION.DAY')}
                        </span></p>
                        <div> {t('SIMULATION.NET_PROFIT_DAILY')} </div>

                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="scroll-item">
                  <Card className="sum-card mb-2 simulation-gradient-card">
                    <Card.Body>
                      <Card.Text className="text">
                        {/* <div>Sum Of Fixed Fees</div> */}

                        <p> <span className={`total-amount-${netProfitClassDaily}`}>
                          {formatNumberWithCurrency(selectedSimulation?.net_profit_monthly ?? 0)}
                        </span></p>
                        <div> {t('SIMULATION.NET_PROFIT_MONTHLY')} </div>

                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="scroll-item">
                      <Card className="sum-card mb-2 simulation-gradient-card">
                        <Card.Body>
                          <Card.Text className="text">
                            <p> <span className={`total-amount-${netProfitClassDaily}`}>
                              {formatNumberWithCurrency(selectedSimulation?.roi_per_order ?? 0, '%')}
                            </span></p>
                            <div> {t('SIMULATION.ROI')} </div>

                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col className="scroll-item">
                      <Card className="sum-card mb-2 simulation-gradient-card">
                        <Card.Body>
                          <Card.Text className="text">
                            <p> <span className={`total-amount-${netProfitClassDaily}`}>
                              {formatNumberWithCurrency(selectedSimulation?.roas_per_order ?? 0, '')}
                            </span></p>
                            <div> {t('SIMULATION.ROAS')} </div>

                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
              </Row>
            </div>
            <Nav variant="tabs" activeKey={activeTab}>
              <Nav.Item>
                <Nav.Link eventKey="order" onClick={() => handleTabChange('order')}> {t('SIMULATION.PER_ORDER')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="time" onClick={() => handleTabChange('time')}> {t('SIMULATION.PER_PERIOD')} </Nav.Link>
              </Nav.Item>
            </Nav>
            <div className='tab-content'>
              {renderTable()}
            </div>
          </Card.Body>
        </Card>
      </>
    );
  };

  return (
    <Container fluid >
          {loading ? (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    ) : (
      <Row>
        <Col md={7}>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h1 className="m-0"> {t('SIMULATION.TITLE')} </h1>
            <Button className='new-simulation-button' variant="primary" onClick={() => setShowModal(true)}>
              <FontAwesomeIcon icon={faPlus} /> {t('SIMULATION.NEW_SIMULATION')}
            </Button>
          </div>
          {/* <Table responsive bordered hover className="custom-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Daily Spend Ads</th>
                <th>Number of Orders</th>
                <th>Net Profit Per Order</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {simulations.map((simulation, index) => (
                <tr key={simulation.id} onClick={() => handleSelectSimulation(simulation)}>
                  <td>{index + 1}</td>
                  <td>{simulation.name}</td>
                  <td>{formatNumberWithCurrency(simulation.daily_ads_spend, '$')}</td>
                  <td>{formatNumberWithCurrency(simulation.orders, 'Order')}</td>
                  <td>{formatNumberWithCurrency(simulation.net_profit)}</td>
                  <td className="actions">
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="action-icon edit-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/dashboard/simulations/${simulation.id}`);
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="action-icon delete-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleConfirmDelete(simulation.id);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table> */}

          {simulations.length === 0 ? <p> {t('SIMULATION.NO_SIMULATIONS')} </p> : simulations.map((simulation, id) => (
             <Card
             key={simulation.id}
             className={`fee-card mb-3 ${selectedSimulation?.id === simulation.id ? 'selected' : ''}`}
             onClick={() => handleSelectSimulation(simulation)}
           >
             <Card.Body>
               <div className="d-flex justify-content-between align-items-center mb-2">
                 <Card.Title className="mb-0">{simulation.name}</Card.Title>
                 <div className="action-buttons">
                   <Button variant="link"  onClick={() => handleSelectSimulation(simulation)} className="btn-icon">
                   <FaEye className="action-icon-edit" />
                   </Button>
                   <Button variant="link" onClick={(e) => {
                     e.stopPropagation();
                     navigate(`/dashboard/simulations/${simulation.id}`);
                   }} className="btn-icon">
                     <FaEdit className="action-icon-edit" />
                   </Button>
                   <Button variant="link" onClick={(e) => {
                     e.stopPropagation();
                     handleConfirmDelete(simulation.id);
                   }} className="btn-icon">
                     <FaTrash className="action-icon-delete" />
                   </Button>
                 </div>
               </div>
               <div className="fee-overview">
                   <div className="fee-overview d-flex flex-wrap">
                   <Card className="simulation-card-insight me-2">
                      {/* <FaUsers className="icon me-2" />  */}
                      <span className='amount'>  {wrapLTR(formatNumberWithCurrency(simulation.daily_ads_spend,'$'))}  </span>
                      <span> {t('SIMULATION.ADS_SPEND')} / {t('SIMULATION.DAY')} </span>
                    </Card>     

                     <Card className="simulation-card-insight me-2">
                      {/* <FaUsers className="icon me-2" />  */}
                      <span className='amount'>{wrapLTR(formatNumberWithCurrency(simulation.cost, '$'))} </span>
                      <span>
                        {t('SIMULATION.COST')}</span>
                    </Card>   
                    
                    <Card className="simulation-card-insight me-2">
                      {/* <FaUsers className="icon me-2" />  */}
                      <span className='amount'>{wrapLTR(formatNumberWithCurrency(simulation.orders, 'Order'))} </span>
                      <span>
                        {t('SIMULATION.ORDERS_PER_DAY')}</span>
                    </Card>
                    <Card className="simulation-card-insight me-2">
                      {/* <FaUsers className="icon me-2" />  */}
                      <span className='amount'>  {wrapLTR(formatNumberWithCurrency(simulation.net_profit, 'DZD'))} </span>
                      <span>  {t('SIMULATION.NET_PROFIT')} </span>
                    </Card>
                    <Card className="simulation-card-insight me-2">
                      {/* <FaUsers className="icon me-2" />  */}
                      <span className='amount'>  {wrapLTR(formatNumberWithCurrency(simulation.roi_per_order, '%'))}  </span>
                      <span> {t('SIMULATION.ROI')} </span>
                    </Card>        
                  </div> 
               </div>
             </Card.Body>
           </Card>
       

          ))}

        </Col>
        <Col md={5}>
          {renderPreview()}
        </Col>
      </Row>
    )}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {t('SIMULATION.ADD_SIMULATION')} </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Container fluid className="wizard">
            <Row>
              <Col xs={12}>
                <div className="stepper">
                  {steps.map((number, index) => (
                    <div key={number} className="step-container">
                      {index < steps.length - 1 && (
                        <div className={`line ${step > number ? 'completed' : ''}`} />
                      )}
                      <div
                        className={`step ${step === number ? 'active' : ''} ${step > number ? 'completed' : ''}`}
                      >
                        {number}
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <h3>{t(`SIMULATION.STEP_${step}`)}</h3>
                <Form>{renderStep()}</Form>
                <div className="button-group mt-4 text-center" >
                  {step > 1 && (
                    <Button variant="secondary" onClick={handlePrevious} className="mr-2 btn-prev">
                      {t('SIMULATION.PREVIOUS')}
                    </Button>
                  )}
                  {step < 3 ? (
                    <Button variant="primary" onClick={handlenewSimulation}>
                      {t('SIMULATION.NEXT')}
                    </Button>
                  ) : (
                    <Button variant="primary" onClick={handleAddSimulation}>
                      {t('SIMULATION.CREATE')}
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </Container>


          {/* <Form>
            <Form.Group as={Row} controlId="simulationName" className="m-3">
              <Form.Label column md={3}>Name</Form.Label>
              <Col md={9}>
                <Form.Control
                  type="text"
                  name="name"
                  value={newSimulation.name}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="simulationDescription" className="m-3">
              <Form.Label column md={3}>Description</Form.Label>
              <Col md={9}>
                <Form.Control
                  type="text"
                  name="description"
                  value={newSimulation.description}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="simulationProductPrice" className="m-3">
              <Form.Label column md={3}>Product Price</Form.Label>
              <Col md={9}>
                <InputGroup>
                  <Form.Control type="text" name="product_price" />
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="simulationCostPerOrder" className="m-3">
              <Form.Label column md={3}>Cost Per Order</Form.Label>
              <Col md={9}>
                <InputGroup>
                  <Form.Control type="text" name="cost_per_order" />
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="simulationDailyAdsSpend" className="m-3">
              <Form.Label column md={3}>Daily Ads Spend</Form.Label>
              <Col md={9}>
                <InputGroup>
                  <Form.Control type="text" name="daily_ads_spend" />
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup>
              </Col>
            </Form.Group>
          </Form> */}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
          <Button variant="primary" onClick={handleAddSimulation}>Add Simulation</Button>
        </Modal.Footer> */}
      </Modal>



      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this simulation?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={handleDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Simulations;