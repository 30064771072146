import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Toast, Button, Row, Col, Form, Card, Table, InputGroup, Accordion, Tabs, Tab, Nav } from 'react-bootstrap';
import axios from '../utils/axios';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './SimulationDetails.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faTag, faList, faMoneyCheckDollar, faCalculator, faCoins, faCalendarDay, faSquareCheck, faDollarSign, faMoneyBillWave, faSyncAlt, faTruck, faBox, faTextSlash, faTextWidth, faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';
import ToastNotification from './ToastNotification'; // Adjust the path based on your directory structure
import ToolsFeesTable from './ToolsFeesTable';
import FixedFeesTable from './FixedFeesTable';
import EmployeeSalariesTable from './EmployeeSalariesTable';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const SimulationDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [simulation, setSimulation] = useState({
    // Initialize full state here...
    sumOfToolsFees: 0,
    sumOfEmployeeSalaries: 0,
    sumOfFixedFees: 0,
  });

  const [feeVariations, setFeeVariations] = useState([]);
  const [selectedFeeVariation, setSelectedFeeVariation] = useState(null);
  const [employees, setEmployees] = useState({});
  const [selectedEmployees, setSelectedEmployees] = useState({});
  const [activeTab, setActiveTab] = useState('order');
  const [netProfitClass, setNetProfitClass] = useState('');
  const [netProfitClassDaily, setNetProfitClassDaily] = useState('');
  const [netProfitClassMonthly, setNetProfitClassMonthly] = useState('');
  const [initialSimulation, setInitialSimulation] = useState({});

  const [showSaveToast, setShowSaveToast] = useState(false);
  const [showCancelToast, setShowCancelToast] = useState(false);

  const toggleSaveToast = () => setShowSaveToast(!showSaveToast);
  const toggleCancelToast = () => setShowCancelToast(!showCancelToast);

  // state variables for accordion panels
  const [isOpenPricing, setIsOpenPricing] = useState(true);
  const handleTogglePricing = () => setIsOpenPricing(prevState => !prevState);


  const [isOpenDelivery, setIsOpenDelivery] = useState(true);
  const handleToggleDelivery = () => setIsOpenDelivery(prevState => !prevState);


  const [isOpenFee, setIsOpenFee] = useState(true);
  const handleToggleFee = () => setIsOpenFee(prevState => !prevState);


  const [isOpenBasic, setIsOpenBasic] = useState(false);
  const handleToggleBasic = () => setIsOpenBasic(prevState => !prevState);

  const formatNumberWithCurrency = (num, currency = 'DZD') => {
    const number = parseFloat(num);

    if (isNaN(number) || number === null) return `0.00 ${currency}`;
    return `${number
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$& ')
      .replace(/\.00$/, '')} ${currency}`;
  };

  const formatNumber = (num) => {
    return !isNaN(num) ? Number(num).toFixed(2) : '0.00';
  };

  const fetchSimulation = useCallback(async () => {
    try {
      const response = await axios.get(`/simulations/${id}`);
      const simulationData = response.data;
      const updatedSimulation = calculateFields(simulationData, 'from fetch simulation');
      if (updatedSimulation.calculate_based_on === 'Enter Cost Manually') {
        updatedSimulation.orders = (
          (updatedSimulation.daily_ads_spend || 0) /
          (updatedSimulation.cost || 1)
        ).toFixed(2);
            } else if (updatedSimulation.calculate_based_on === 'Enter Orders Number') {
        updatedSimulation.cost = ((updatedSimulation.daily_ads_spend || 0) / (updatedSimulation.orders || 1) ).toFixed(2);;
      }

      const response02 = await axios.put(`/simulations/${id}`, updatedSimulation);
      //const updatedSimulation = simulationData;
      if (updatedSimulation.calculate_based_on === 'Enter Cost Manually') {
        updatedSimulation.orders = (
          (updatedSimulation.daily_ads_spend || 0) /
          (updatedSimulation.cost || 1)
        ).toFixed(2);
        updatedSimulation.cost = ((updatedSimulation.daily_ads_spend || 0) / (updatedSimulation.orders || 1) ).toFixed(2);;
      }
      setSimulation(updatedSimulation);
      setInitialSimulation(updatedSimulation);
      setSelectedFeeVariation(simulationData.fee_variation_id);

      // Initialize selected employees from the fetched simulation data
      const initialSelectedEmployees = (simulationData?.selected_employees || []).reduce((acc, item) => {
        acc[item.role] = item.employeeId;
        return acc;
      }, {});

      setSelectedEmployees(initialSelectedEmployees);
    } catch (error) {
      console.error('Error fetching simulation data', error);
    }
  }, [id]);

  const fetchFeeVariations = useCallback(async () => {
    try {
      const response = await axios.get('/fee-variations', { withCredentials: true });
      setFeeVariations(response.data);
    } catch (error) {
      console.error('Error fetching fee variations data', error);
    }
  }, []);

  useEffect(() => {
    fetchSimulation();
    fetchFeeVariations();
  }, [fetchSimulation, fetchFeeVariations]);

  const handleSelectFeeVariation = (e) => {
    const feeVariationId = e.target.value === '' ? null : parseInt(e.target.value, 10);
    setSelectedFeeVariation(feeVariationId);
    setSelectedEmployees({});

    const resetSimulationState = {
      sumOfToolsFees: 0,
      sumOfEmployeeSalaries: 0,
      sumOfFixedFees: 0,
      selected_employees: [],
      fee_role_per_order: 0,
      other_fee_per_order: 0,
      fee_variation_id: feeVariationId
    };

    let resetSimulation;
    if (feeVariationId === null) {
      const feeVariation = feeVariations.find(fv => fv.id === feeVariationId);
      const sums = calculateSums(feeVariation, simulation.euro_price);
      const totals = calculateTotals(sums, simulation.delivered_orders_daily);

      resetSimulation = {
        ...simulation,
        ...resetSimulationState,
        fee_variation_id: null,
        ...sums,
        ...totals
      };
    } else {
      const feeVariation = feeVariations.find(fv => fv.id === feeVariationId);
      const sums = calculateSums(feeVariation, simulation.euro_price);
      const totals = calculateTotals(sums, simulation.delivered_orders_daily);

      resetSimulation = {
        ...simulation,
        ...sums,
        ...totals,
        fee_variation_id: feeVariationId
      };
    }

   // const updatedSimulation = calculateFields(resetSimulation, 'from handleSelectFeeVariation');
    setSimulation(resetSimulation);
  };

  const handleEmployeeChange = (roleId, employeeId) => {
    setSelectedEmployees(prev => {
      const updatedEmployees = { ...prev, [roleId]: employeeId };
      const updatedSimulation = {
        ...simulation,
        selected_employees: Object.entries(updatedEmployees).map(([role, id]) => ({ role, employeeId: id })),
      };
      setSimulation(calculateFields(updatedSimulation, 'from handleEmployeeChange'));
      return updatedEmployees;
    });
  };
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const selectedFeeVariationData = useMemo(() => {
    return (
      feeVariations.find(
        (fv) => fv.id === selectedFeeVariation
      ) || {
        ToolFees: [],
        FixedFees: [],
        EmployeeSalaries: [],
      }
    );
  }, [selectedFeeVariation, feeVariations]);

  const filteredEmployeesByRole = useMemo(() => {
    if (!selectedFeeVariationData || !selectedFeeVariationData.EmployeeSalaries) return {};

    return selectedFeeVariationData.EmployeeSalaries.reduce((acc, salary) => {
      if (salary.variable_amount > 0) {
        if (!acc[salary.role]) {
          acc[salary.role] = [];
        }
        acc[salary.role].push(salary);
      }
      return acc;
    }, {});
  }, [selectedFeeVariationData]);

  useEffect(() => {
    if (selectedFeeVariationData && selectedFeeVariationData.EmployeeSalaries) {
      const employeesData = selectedFeeVariationData.EmployeeSalaries.reduce((acc, salary) => {
        if (salary.calculation_basis === 'variable' || salary.calculation_basis === 'both') {
          if (!acc[salary.role]) {
            acc[salary.role] = [];
          }
          acc[salary.role].push({
            id: salary.id,
            name: salary.name,
            variable_amount: salary.variable_amount,
            per_order_basis: salary.per_order_basis
          });
        }
        return acc;
      }, {});
      setEmployees(employeesData);
    }
  }, [selectedFeeVariation, selectedFeeVariationData]);

  useEffect(() => {
    if (selectedFeeVariation !== null) {
      const feeVariation = feeVariations.find(fv => fv.id === selectedFeeVariation);
      if (feeVariation) {
        const employeesData = selectedFeeVariationData.EmployeeSalaries.reduce((acc, salary) => {
          if (salary.calculation_basis === 'variable' || salary.calculation_basis === 'both') {
            if (!acc[salary.role]) {
              acc[salary.role] = [];
            }
            acc[salary.role].push({
              id: salary.id,
              name: salary.name,
              variable_amount: salary.variable_amount,
              per_order_basis: salary.per_order_basis
            });
          }
          return acc;
        }, {});
        setEmployees(employeesData);
      };

      const sums = calculateSums(feeVariation, simulation.euro_price);
      const totals = calculateTotals(sums, simulation.delivered_orders_daily);

      let updatedSimulation = {
        ...simulation,
        fee_variation_id: selectedFeeVariation,
        ...sums,
        ...totals,
      };
      updatedSimulation = calculateFields(updatedSimulation, 'from useEffect (selectedFeeVariation, simulation.euro_price, simulation.delivered_orders_daily)');
      setSimulation(updatedSimulation);
    }
  }, [selectedFeeVariation, feeVariations, simulation.euro_price, simulation.delivered_orders_daily]);

  const calculateFields = (data, functionfrom='idk') => {
    data.monthly_ads_spend = data.daily_ads_spend * 30;
    data.fee_role_per_order = 0;
    let totalFeeRolePerOrder = 0;
    // Instead of referencing simulation.selected_employees, we use the new selectedEmployees state coming into the function
    Object.keys(data.selected_employees || {}).forEach(role => {
      const employeeId = data.selected_employees[role].employeeId;
      role = data.selected_employees[role].role
      if (employees[role]) {
        const selectedEmp = employees[role].find(emp => emp.id === parseInt(employeeId, 10));
        if (selectedEmp) {
          let feeRolePerOrder = 0;
          if (selectedEmp.per_order_basis === 'delivered') {
            feeRolePerOrder = selectedEmp.variable_amount;
          } else if (selectedEmp.per_order_basis === 'confirmed') {
            feeRolePerOrder = (selectedEmp.variable_amount * data.confirmed_orders_daily) / data.delivered_orders_daily;
          }
          totalFeeRolePerOrder += feeRolePerOrder;
        }
      }
    });
      data.fee_role_per_order = totalFeeRolePerOrder;
      data.confirmation_cost_per_order = (data.euro_price * data.cost * 100) / data.confirmation_percentage;
      data.delivery_cost_per_order = (data.confirmation_cost_per_order * 100) / data.delivery_percentage;
      data.confirmed_orders_daily = (data.euro_price * data.daily_ads_spend) / data.confirmation_cost_per_order;
      data.confirmed_orders_monthly = data.confirmed_orders_daily * 30;
      data.delivered_orders_daily = (data.euro_price * data.daily_ads_spend) / data.delivery_cost_per_order;
      data.delivered_orders_monthly = data.delivered_orders_daily * 30;
    

      data.return_fee_daily = (data.confirmed_orders_daily - data.delivered_orders_daily) * data.return_fee;
      data.return_fee_monthly = data.return_fee_daily * 30;
    

    data.cod_fee_per_order = (data.cod_fee / 100) * data.sale_price;
    data.cod_fee_daily = data.delivered_orders_daily * data.cod_fee_per_order;
    data.cod_fee_monthly = data.cod_fee_daily * 30;

    data.return_fee_per_order = data.return_fee_daily / data.delivered_orders_daily;

    data.net_profit = data.sale_price - (data.product_price + data.cod_fee_per_order + data.return_fee_per_order + data.other_fee_per_order + Number(data.delivery_price) + Number(data.packaging_fee) + data.delivery_cost_per_order + data.fee_role_per_order);
    data.net_profit_daily = data.net_profit * data.delivered_orders_daily;
    data.net_profit_monthly = data.net_profit_daily * 30;

    // Ensure all components involved in the ROI calculation are valid numbers
    const costComponentsSum = (Number(data.product_price) + Number(data.delivery_cost_per_order) + Number(data.packaging_fee) + Number(data.other_fee_per_order));
    if (costComponentsSum !== 0) {
      data.roi_per_order = (Number(data.net_profit) / costComponentsSum) * 100;
    } else {
      data.roi_per_order = 0;
    }
    data.roas_per_order = data.sale_price / (data.cost * data.euro_price)
    return data;
  };

  useEffect(() => {
    if (simulation) {
      setNetProfitClass(simulation.net_profit >= 0 ? 'positive' : 'negative');
      setNetProfitClassDaily(simulation.net_profit_daily >= 0 ? 'positive' : 'negative');
      setNetProfitClassMonthly(simulation.net_profit_monthly >= 0 ? 'positive' : 'negative');
    }
  }, [simulation?.net_profit, simulation?.net_profit_daily, simulation?.net_profit_monthly]);

  const calculateSums = (feeVariation, euroPrice) => {
    if (!feeVariation) return {
      sumOfFixedFees: 0,
      sumOfEmployeeSalaries: 0,
      tools_fee_monthly: 0
    };
  
    const adjustSum = (sum, frequency, amount) => {
      switch (frequency) {
        case 'Daily':
          return sum + (amount * 30); // Assume 30 days in a month
        case 'Monthly':
          return sum + amount;
        case 'Quarterly':
          return sum + (amount / 3);
        case 'Yearly':
          return sum + (amount / 12);
        default:
          return sum;
      }
    };
  
    let sumOfToolsFeesDZD = 0;
    // let sumOfToolsFeesUSD = 0;
    
    // feeVariation.ToolFees.forEach((fee) => {
    //   if (fee.currency === 'DZD') {
    //     sumOfToolsFeesDZD += adjustSum(0, fee.frequency, fee.amount);
    //   } else {
    //     sumOfToolsFeesUSD += adjustSum(0, fee.frequency, fee.amount);
    //   }
    // });
    sumOfToolsFeesDZD = feeVariation.ToolFees.reduce((sum, fee) => adjustSum(sum, fee.frequency, fee.amount_in_DZD), 0);
    const sumOfFixedFees = feeVariation.FixedFees.reduce((sum, fee) => adjustSum(sum, fee.frequency, fee.amount), 0);
    const sumOfEmployeeSalaries = feeVariation.EmployeeSalaries.reduce((sum, salary) => sum + salary.fixed_amount, 0);
  
    const tools_fee_monthly = sumOfToolsFeesDZD;
  
    return {
      sumOfFixedFees,
      sumOfEmployeeSalaries,
      tools_fee_monthly
    };
  }

  const calculateTotals = (sums, deliveredOrdersDaily) => {
    const { sumOfEmployeeSalaries, sumOfFixedFees, tools_fee_monthly } = sums;

    const tools_fee_daily = tools_fee_monthly / 30;

    const employee_salary_monthly = sumOfEmployeeSalaries;
    const employee_salary_daily = employee_salary_monthly / 30;

    const fixed_fee_monthly = sumOfFixedFees;
    const fixed_fee_daily = fixed_fee_monthly / 30;

    const total_fee_daily = fixed_fee_daily + tools_fee_daily + employee_salary_daily;

    const other_fee_per_order = total_fee_daily / deliveredOrdersDaily;

    return {
      tools_fee_daily,
      employee_salary_daily,
      employee_salary_monthly,
      fixed_fee_daily,
      fixed_fee_monthly,
      total_fee_daily,
      other_fee_per_order
    };
  };
  const handleUpdateSimulation = async () => {
    try {
      const updatedSimulation = {
        ...simulation,
        selected_employees: Object.entries(selectedEmployees).map(([role, employeeId]) => ({
          role,
          employeeId,
        })),
      };
      const recalculatedSimulation = calculateFields(updatedSimulation, 'from handleUpdatesSimulation');
      const response = await axios.put(`/simulations/${id}`, recalculatedSimulation);
      setSimulation(response.data);
      setInitialSimulation(response.data);
      toggleSaveToast();
    } catch (error) {
      console.error('Error updating simulation', error);
      // Handle error (e.g., show toast notification)
      toast.error('Error updating simulation. Please try again.');
    }
  };
  const handleCancelUpdate = () => {
    setSimulation(initialSimulation);
    toggleCancelToast();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Parse the input value based on the field name
    let newValue;
    if (value === '') {
      newValue = null;
    } else if (['packaging_fee', 'product_price', 'sale_price', 'euro_price', 'daily_ads_spend', 'cost', 'orders', 'confirmation_percentage', 'delivery_percentage', 'return_fee', 'cod_fee'].includes(name)) {
      newValue = parseFloat(value);
      if (isNaN(newValue)) newValue = 0;  // Handle potential NaN cases
    } else {
      newValue = value;
    }

    let updatedSimulation = { ...simulation, [name]: newValue };

    if (['calculate_based_on', 'cost', 'orders', 'daily_ads_spend'].includes(name)) {
      if (updatedSimulation.calculate_based_on === 'Enter Cost Manually') {
        updatedSimulation.orders = (
          (updatedSimulation.daily_ads_spend || 0) /
          (updatedSimulation.cost || 1)
        ).toFixed(2);
            } else if (updatedSimulation.calculate_based_on === 'Enter Orders Number') {
        updatedSimulation.cost = ((updatedSimulation.daily_ads_spend || 0) / (updatedSimulation.orders || 1) ).toFixed(2);;
      }
    }

    updatedSimulation = calculateFields(updatedSimulation, 'from handleChange');
    setSimulation(updatedSimulation);
  };

  const handleSliderChange = (value, name) => {
    const updatedSimulation = { ...simulation, [name]: value };
    const recalculatedSimulation = calculateFields(updatedSimulation, 'from handleSliderChange');
    setSimulation(recalculatedSimulation);
  };

  const renderTable = () => {
    if (activeTab === 'order') {
      return (
        <>
          <p className='mt-3'>This is the pricing per order</p>
          <Table responsive bordered striped hover className="custom-table mb-4">
            <thead>
              <tr>
                <th className='th-label-simulation'>{t('SIMULATION.LABEL')}</th>
                <th>{t('SIMULATION.PRICE')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='td-label'>{t('SIMULATION.PRODUCT_PRICE')}</td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.product_price, 'DZD')}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.SALE_PRICE')}</td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.sale_price, 'DZD')}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.DELIVERY_PRICE')}</td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.delivery_price, 'DZD')}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.PACKAGING_FEE')}</td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.packaging_fee, 'DZD')}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.CONFIRMATION_COST_PER_ORDER')}</td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.confirmation_cost_per_order, 'DZD')}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.DELIVERY_COST_PER_ORDER')}</td>
                <td className="table-amount" style={{ color: "green" }}>{formatNumberWithCurrency(simulation?.delivery_cost_per_order, 'DZD')}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.RETURN_FEE_PER_ORDER')}  </td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.return_fee_per_order, 'DZD')}</td>
              </tr>
              <tr>
                <td className='td-label'> {t('SIMULATION.COD_FEE_PER_ORDER')} </td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.cod_fee_per_order, 'DZD')}</td>
              </tr>
              <tr>
                <td className='td-label'> {t('SIMULATION.OTHER_FEE_PER_ORDER')} </td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.other_fee_per_order, 'DZD')}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.FEE_ROLE_PER_ORDER')}</td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.fee_role_per_order, 'DZD')}</td>
              </tr>
            </tbody>
          </Table>

          <Table responsive bordered striped hover className="employee-variant-table custom-table mb-4">
            <thead>
              <tr>
                <th> {t('SIMULATION.ROLE')} </th>
                <th> {t('SIMULATION.EMPLOYEE')} </th>
                <th> {t('SIMULATION.EMPLOYEE_VARIABLE_AMOUNT')}</th>
                <th> {t('SIMULATION.EMPLOYEE_PERCENTAGE')}</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(filteredEmployeesByRole).map(([role, roleEmployees]) => (
                <tr key={role}>
                  <td>{role}</td>
                  <td>
                    <Form.Control
                      as="select"
                      value={selectedEmployees[role] || ''}
                      onChange={e => handleEmployeeChange(role, e.target.value)}
                      className="form-control-custom custom-select-arrow"
                    >
                      <option value="">{t('SIMULATION.SELECT_EMPLOYEE')}</option>
                      {roleEmployees.map(emp => (
                        <option key={emp.id} value={emp.id}>
                          {emp.name}
                        </option>
                      ))}
                    </Form.Control>
                  </td>
                  <td>
                    {(() => {
                      const selectedEmp = selectedEmployees[role] && employees[role]?.find(emp => emp.id === parseInt(selectedEmployees[role], 10));
                      if (selectedEmp) {
                        return `${selectedEmp.variable_amount} DZD`;
                      }
                      return '';
                    })()}

                  </td>
                  <td>
                    {(() => {
                      const selectedEmp = selectedEmployees[role] && employees[role]?.find(emp => emp.id === parseInt(selectedEmployees[role], 10));
                      if (selectedEmp) {
                        if (selectedEmp.per_order_basis === "confirmed") {
                          return <span className={`custom-badge custom-badge-confirmed ms-2`}> {t('SIMULATION.CONFIRMED')} </span>;
                        } else if (selectedEmp.per_order_basis === "delivered") {
                          return <span className={`custom-badge custom-badge-delivered ms-2`}> {t('SIMULATION.DELIVERED')} </span>;
                        }
                      }
                      return '';
                    })()}
                  </td>
                </tr>
              ))}



            </tbody>
          </Table>

          <div className="footer">
            <span className="total-profit">{t('SIMULATION.NET_PROFIT')}</span>
            <span className={`total-amount-${netProfitClass}`}>{formatNumberWithCurrency(simulation.net_profit)}</span>
          </div>

          <div className="footer">
            <span className="total-profit"> {t('SIMULATION.ROI')} </span>
            <span className={`total-amount-${netProfitClass}`}>{formatNumberWithCurrency(simulation.roi_per_order, '%')}</span>
          </div><div className="footer">
            <span className="total-profit"> {t('SIMULATION.ROAS')} </span>
            <span className={`total-amount-${netProfitClass}`}>{formatNumberWithCurrency(simulation.roas_per_order, '')}</span>
          </div>
        </>
      );
    } else if (activeTab === 'time') {
      return (
        <>
          <Table responsive bordered striped hover className="custom-table mb-4">
            <thead>
              <tr>
              <th className='th-label-simulation'>{t('SIMULATION.LABEL')}</th>
              <th>{t('SIMULATION.DAILY')}</th>
                <th>{t('SIMULATION.MONTHLY')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='td-label'> {t('SIMULATION.ADS_SPEND')}</td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.daily_ads_spend, '$')}</td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.monthly_ads_spend, '$')}</td>
              </tr>
              <tr>
                <td className='td-label'> {t('SIMULATION.CONFIRMED_ORDERS')}</td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.confirmed_orders_daily, 'Orders')}</td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.confirmed_orders_monthly, 'Orders')}</td>

              </tr>
              <tr>
                <td className='td-label'> {t('SIMULATION.DELIVERED_ORDERS')}</td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.delivered_orders_daily, 'Orders')}</td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.delivered_orders_monthly, 'Orders')}</td>
              </tr>
              <tr>
                <td className='td-label'> {t('SIMULATION.RETURN_FEE')}</td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.return_fee_daily)}</td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.return_fee_monthly)}</td>
              </tr>
              <tr>
                <td className='td-label'> {t('SIMULATION.COD_FEE')}</td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.cod_fee_daily)}</td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.cod_fee_monthly)}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.TOOLS_FEE')}</td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.tools_fee_daily)}</td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.tools_fee_monthly)}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.FIXED_FEE')}</td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.fixed_fee_daily)}</td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.fixed_fee_monthly)}</td>
              </tr>
              <tr>
                <td className='td-label'>{t('SIMULATION.EMPLOYEE_WAGE')}</td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.employee_salary_daily)}</td>
                <td className="table-amount">{formatNumberWithCurrency(simulation?.employee_salary_monthly)}</td>
              </tr>
            </tbody>
          </Table>

          <div className="footer-table">

            <Table responsive borderless hover className="custom-table mb-4">
              <tbody>
                <tr>
                  <td className="td-label total-profit" style={{ width: '200px' }}>{t('SIMULATION.NET_PROFIT')}</td>
                  <td className={`total-amount-${netProfitClassDaily}`}>
                    {formatNumberWithCurrency(simulation?.net_profit_daily ?? 0)}
                  </td>
                  <td className={`total-amount-${netProfitClassMonthly}`}>
                    {formatNumberWithCurrency(simulation?.net_profit_monthly ?? 0)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

        </>
      );
    }
  };

  if (!simulation) {
    return <div>{t('SIMULATION.SIMULATION_NOT_FOUND')}</div>;
  }

  return (
    <Container className="simulation-details-container">
    <ToastContainer position="top-right" />
      <Row className="align-items-center mb-2">
        <Col lg={9}  xs={12}>
          <div className="section-title">
            {t('SIMULATION.SIMULATION_DETAILS')}
          </div>
          <div className="section-description">
            {t('SIMULATION.SIMULATION_DETAILS_DESCRIPTION')}
          </div>
        </Col>
        <Col lg={3} xs={12} className="ml-auto">
          <Button variant="secondary" onClick={handleCancelUpdate} className="mt-3 mr-3 ml-3"> {t('SIMULATION.CANCEL')} </Button>
          <Button variant="primary" onClick={handleUpdateSimulation} className="mt-3"> {t('SIMULATION.UPDATE')} </Button>
        </Col>
      </Row>
      <Form className='p-8'>
        <Row>
          <Col xs={12} lg={7}>
            <Accordion defaultActiveKey="0">
              <Card className="mb-3 custom-card">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">{t('SIMULATION.BASIC_DETAILS')}</h5>
                  <Button
                    variant="link"
                    onClick={handleToggleBasic}
                    aria-expanded={isOpenBasic}
                    aria-controls="collapse-body"
                  >
                    <FontAwesomeIcon icon={isOpenBasic ? faChevronUp : faChevronDown} />
                  </Button>
                </Card.Header>
                <Accordion.Collapse eventKey="0" in={isOpenBasic}>
                  <Card.Body>
                    <Form className="form-custom">
                      <Row>
                        <Col xs={12} md={6}>
                          <Form.Group controlId="name" className="form-group-custom">
                            <Form.Label> {t('SIMULATION.NAME')}</Form.Label>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                name="name"
                                required
                                value={simulation.name}
                                onChange={handleChange}
                                className="form-control-custom"
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                          <Form.Group controlId="description" className="form-group-custom">
                            <Form.Label> {t('SIMULATION.DESCRIPTION')}</Form.Label>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                as="textarea"
                                name="description"
                                value={simulation.description}
                                onChange={handleChange}
                                className="form-control-custom"
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>

                      </Row>
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>

            <Accordion defaultActiveKey="0">
              <Card className="mb-3 custom-card">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">{t('SIMULATION.PRICING')}</h5>
                  <Button
                    variant="link"
                    onClick={handleTogglePricing}
                    aria-expanded={isOpenPricing}
                    aria-controls="collapse-body"
                  >
                    <FontAwesomeIcon icon={isOpenPricing ? faChevronUp : faChevronDown} />
                  </Button>
                </Card.Header>
                <Accordion.Collapse eventKey="0" in={isOpenPricing}>
                  <Card.Body>
                    <Form className="form-custom">
                      <Row>
                        <Col xs={12} md={6}>
                          <Form.Group controlId="productPrice" className="form-group-custom">
                            <Form.Label> <FontAwesomeIcon icon={faTag} /> {t('SIMULATION.PRODUCT_PRICE')}</Form.Label>
                            <InputGroup>
                              <Form.Control
                                type="number"
                                name="product_price"
                                value={simulation.product_price}
                                onChange={handleChange}
                                className="form-control-custom"
                              />
                              <InputGroup.Text>DZD</InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                          <Form.Group controlId="salePrice" className="form-group-custom">
                            <Form.Label> <FontAwesomeIcon icon={faCoins} /> {t('SIMULATION.SALE_PRICE')}</Form.Label>
                            <InputGroup>
                              <Form.Control
                                type="number"
                                name="sale_price"
                                value={simulation.sale_price}
                                onChange={handleChange}
                                className="form-control-custom"
                              />
                              <InputGroup.Text>DZD</InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Group controlId="euroPrice" className="form-group-custom">
                            <Form.Label> <FontAwesomeIcon icon={faDollarSign} /> {t('SIMULATION.DOLLAR_PRICE')}</Form.Label>
                            <InputGroup>
                              <Form.Control
                                type="number"
                                name="euro_price"
                                value={simulation.euro_price}
                                onChange={handleChange}
                                className="form-control-custom"
                              />
                              <InputGroup.Text>DZD</InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                          <Form.Group controlId="dailyAdsSpend" className="form-group-custom">
                            <Form.Label> <FontAwesomeIcon icon={faCalendarDay} /> {t('SIMULATION.DOLLAR_SPEND_PER_DAY')}</Form.Label>
                            <InputGroup>
                              <Form.Control
                                type="number"
                                name="daily_ads_spend"
                                value={simulation.daily_ads_spend}
                                onChange={handleChange}
                                className="form-control-custom"
                              />
                              <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Group controlId="calculationBasis" className="form-group-custom">
                            <Form.Label> <FontAwesomeIcon icon={faCalculator} /> {t('SIMULATION.CALCULATION_BASED_ON')}</Form.Label>
                            <Form.Control
                              as="select"
                              name="calculate_based_on"
                              value={simulation.calculate_based_on}
                              onChange={handleChange}
                              className="form-control-custom custom-select"
                            >
                              <option value="Enter Cost Manually">{t('SIMULATION.ENTER_COST_MANUALLY')}</option>
                              <option value="Enter Orders Number">{t('SIMULATION.ENTER_ORDERS_NUMBER')}</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                          {simulation.calculate_based_on === 'Enter Cost Manually' ? (
                            <>
                              <Form.Group controlId="cost" className="form-group-custom">
                                <Form.Label> <FontAwesomeIcon icon={faMoneyCheckDollar} /> {t('SIMULATION.COST')}</Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type="number"
                                    name="cost"
                                    value={simulation.cost}
                                    onChange={handleChange}
                                    className="form-control-custom"
                                  />
                                  <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                              <Form.Group controlId="orders" className="form-group-custom">
                                <Form.Label> <FontAwesomeIcon icon={faList} /> {t('SIMULATION.ORDERS')}</Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type="number"
                                    name="orders"
                                    disabled
                                    value={formatNumber(simulation.orders, 2) || simulation.orders}
                                    className="form-control-custom"
                                  />
                                  <InputGroup.Text>Orders</InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                            </>
                          ) : (
                            <>
                              <Form.Group controlId="costOrder" className="form-group-custom">
                                <Form.Label> <FontAwesomeIcon icon={faMoneyCheckDollar} /> {t('SIMULATION.COST')}</Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type="number"
                                    name="cost_per_order"
                                    value={formatNumber(simulation.cost, 2) || simulation.cost}
                                    disabled
                                    className="form-control-custom"
                                  />
                                  <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                              <Form.Group controlId="orders" className="form-group-custom">
                                <Form.Label><FontAwesomeIcon icon={faList} /> {t('SIMULATION.ORDERS')}</Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type="number"
                                    name="orders"
                                    value={simulation.orders}
                                    onChange={handleChange}
                                    className="form-control-custom"
                                  />
                                  <InputGroup.Text>Orders</InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                            </>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>

            <Accordion defaultActiveKey="0">
              <Card className="mb-3 custom-card">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-0"> {t('SIMULATION.DELIVERY')} </h5>
                  <Button
                    variant="link"
                    onClick={handleToggleDelivery}
                    aria-expanded={isOpenDelivery}
                    aria-controls="collapse-body"
                  >
                    <FontAwesomeIcon icon={isOpenDelivery ? faChevronUp : faChevronDown} />
                  </Button>
                </Card.Header>
                <Accordion.Collapse eventKey="0" in={isOpenDelivery}>
                  <Card.Body>
                    <Row>
                      <Col xs={12} md={6}>
                        <Form.Group controlId="delivery_price" className="form-group-custom">
                          <Form.Label><FontAwesomeIcon icon={faMoneyBillTransfer} /> {t('SIMULATION.DELIVERY_PRICE')}</Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="delivery_price"
                              value={simulation.delivery_price}
                              onChange={handleChange}
                              className="form-control-custom"
                            />
                            <InputGroup.Text>DZD</InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="packaging_fee" className="form-group-custom">
                          <Form.Label><FontAwesomeIcon icon={faMoneyBillTransfer} /> {t('SIMULATION.PACKAGING_FEE')}</Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="packaging_fee"
                              value={simulation.packaging_fee}
                              onChange={handleChange}
                              className="form-control-custom"
                            />
                            <InputGroup.Text>DZD</InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="confirmation_percentage" className="form-group-custom">
                          <Form.Label><FontAwesomeIcon icon={faSquareCheck} /> {t('SIMULATION.CONFIRMATION_PERCENTAGE')}</Form.Label>
                          <Slider
                            min={0}
                            max={100}
                            value={simulation.confirmation_percentage || 0}
                            onChange={(value) => handleSliderChange(value, 'confirmation_percentage')}
                            className="slider-custom"
                          />
                          <Form.Text className="form-text-custom">{simulation.confirmation_percentage}%</Form.Text>
                        </Form.Group>
                        <Form.Group controlId="delivery_percentage" className="form-group-custom">
                          <Form.Label><FontAwesomeIcon icon={faTruck} /> {t('SIMULATION.DELIVERY_PERCENTAGE')}</Form.Label>
                          <Slider
                            min={0}
                            max={100}
                            value={simulation.delivery_percentage || 0}
                            onChange={(value) => handleSliderChange(value, 'delivery_percentage')}
                            className="slider-custom"
                          />
                          <Form.Text className="form-text-custom">{simulation.delivery_percentage}%</Form.Text>
                        </Form.Group>

                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group controlId="codFee" className="form-group-custom">
                          <Form.Label><FontAwesomeIcon icon={faMoneyBillWave} /> {t('SIMULATION.COD_FEE')}</Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="cod_fee"
                              value={simulation.cod_fee}
                              onChange={handleChange}
                              className="form-control-custom"
                            />
                            <InputGroup.Text>DZD</InputGroup.Text>
                          </InputGroup>
                        </Form.Group>

                        <Form.Group controlId="returnFee" className="form-group-custom">
                          <Form.Label><FontAwesomeIcon icon={faBox} /> {t('SIMULATION.RETURN_FEE')}</Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="return_fee"
                              value={simulation.return_fee}
                              onChange={handleChange}
                              className="form-control-custom"
                            />
                            <InputGroup.Text>DZD</InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>

            <Accordion defaultActiveKey="0">
              <Card className="mb-3 custom-card">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-0"> {t('SIMULATION.FEES')} </h5>
                  <Button
                    variant="link"
                    onClick={handleToggleFee}
                    aria-expanded={isOpenFee}
                    aria-controls="collapse-body"
                  >
                    <FontAwesomeIcon icon={isOpenFee ? faChevronUp : faChevronDown} />
                  </Button>
                </Card.Header>
                <Accordion.Collapse eventKey="0" in={isOpenFee}>
                  <Card.Body>
                    <Row>
                      <Form.Group controlId="simulationFeeVariation" className="select-dropdown m-2">
                        <Form.Label>{t('SIMULATION.FEE_VARIATION')}</Form.Label>
                        <Form.Control
                          as="select"
                          name="fee_variation_id"
                          value={simulation.fee_variation_id}
                          onChange={handleSelectFeeVariation}
                          className="form-control-custom"
                        >
                          <option value="">{t('SIMULATION.SELECT_FEE_VARIATION')}</option>
                          {feeVariations.map((fv) => (
                            <option key={fv.id} value={fv.id}>
                              {fv.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>

                      {selectedFeeVariationData && (
                        <Row className='m-2' >
                          <Tabs defaultActiveKey="employeeSalaries">
                            <Tab eventKey="employeeSalaries" title="Employee Salaries">
                              <EmployeeSalariesTable fees={selectedFeeVariationData.EmployeeSalaries} setVariation={setSelectedFeeVariation} variation={selectedFeeVariationData} allowEdit={false} />
                            </Tab>
                            <Tab eventKey="fixedFees" title="Fixed Fees">
                              <FixedFeesTable fees={selectedFeeVariationData.FixedFees} setVariation={setSelectedFeeVariation} variation={selectedFeeVariationData} allowEdit={false} />
                            </Tab>
                            <Tab eventKey="toolsFees" title="Tools Fees">
                              <ToolsFeesTable fees={selectedFeeVariationData.ToolFees} setVariation={setSelectedFeeVariation} variation={selectedFeeVariationData} allowEdit={false} />
                            </Tab>

                          </Tabs>
                        </Row>
                      )}
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>

            <Row className="justify-content-end">
              <Col xs="auto">
                <Button variant="secondary" onClick={handleCancelUpdate} className="mt-3">{t('SIMULATION.CANCEL')}</Button>
              </Col>
              <Col xs="auto">
                <Button variant="primary" onClick={handleUpdateSimulation} className="mt-3">{t('SIMULATION.UPDATE')}</Button>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={5}>
            <Card className="mb-4 preview custom-card">
              <Card.Body>
                <h6>{simulation.name}</h6>
                <div className="scroll-container mb-3">

                  <Row className="flex-nowrap">
                    <Col className="scroll-item">
                      <Card className="sum-card mb-2 simulation-gradient-card">
                        <Card.Body>
                          <Card.Text className="text">
                            <p> <span className={`total-amount-${netProfitClass}`}>
                              {formatNumberWithCurrency(simulation?.net_profit ?? 0)}
                            </span></p>
                            <div className='text'>  {t('SIMULATION.NET_PROFIT')} </div>

                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col className="scroll-item">
                      <Card className="sum-card mb-2 simulation-gradient-card">
                        <Card.Body>
                          <Card.Text className="text">
                            <p> <span className={`total-amount-${netProfitClassDaily}`}>
                              {formatNumberWithCurrency(simulation?.net_profit_daily ?? 0)} / Day
                            </span></p>
                            <div> {t('SIMULATION.NET_PROFIT_DAILY')} </div>

                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col className="scroll-item">
                      <Card className="sum-card mb-2 simulation-gradient-card">
                        <Card.Body>
                          <Card.Text className="text">
                            <p> <span className={`total-amount-${netProfitClassDaily}`}>
                              {formatNumberWithCurrency(simulation?.net_profit_monthly ?? 0)}
                            </span></p>
                            <div> {t('SIMULATION.NET_PROFIT_MONTHLY')} </div>

                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col className="scroll-item">
                      <Card className="sum-card mb-2 simulation-gradient-card">
                        <Card.Body>
                          <Card.Text className="text">
                            <p> <span className={`total-amount-${netProfitClassDaily}`}>
                              {formatNumberWithCurrency(simulation?.roi_per_order ?? 0, '%')}
                            </span></p>
                            <div>ROI </div>

                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col className="scroll-item">
                      <Card className="sum-card mb-2 simulation-gradient-card">
                        <Card.Body>
                          <Card.Text className="text">
                            <p> <span className={`total-amount-${netProfitClassDaily}`}>
                              {formatNumberWithCurrency(simulation?.roas_per_order ?? 0, '')}
                            </span></p>
                            <div>ROAS </div>

                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>


                <Nav variant="tabs" activeKey={activeTab} className="nav-tabs mt-3">
                  <Nav.Item>
                    <Nav.Link eventKey="order" onClick={() => handleTabChange('order')}>{t('SIMULATION.PER_ORDER')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="time" onClick={() => handleTabChange('time')}>{t('SIMULATION.PER_PERIOD')}</Nav.Link>
                  </Nav.Item>
                </Nav>
                <div className='tab-content' >

                  {renderTable()}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>


      <ToastNotification
        show={showSaveToast}
        onClose={toggleSaveToast}
        message="Changes are saved!"
        type="success"
      />
      <ToastNotification
        show={showCancelToast}
        onClose={toggleCancelToast}
        message="Changes are cancelled!"
        type="error"
      />
    </Container>
  );
};

export default SimulationDetails;
