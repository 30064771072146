import React, { useState, useEffect } from 'react';
import { Table, Button, Form, FormControl, Modal, InputGroup, Container, Col, Row, Spinner, Pagination } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import axios from '../utils/axios';
import Avatar1 from '../assets/avatar1.jpg';
import Avatar2 from '../assets/avatar2.jpg';
import { useNavigate } from 'react-router-dom';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [showUserModal, setShowUserModal] = useState(false);
  const [newUser, setNewUser] = useState({ avatar: '', name: '', email: '', country: '', phoneNumber: '', password: '' });
  const [errors, setErrors] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 10;
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, [page, searchQuery]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/user/all-users', { params: { search: searchQuery, page, limit } });
      setUsers(response.data.users || []);
      setTotalPages(Math.ceil(response.data.count / limit));
    } catch (error) {
      console.error("Error fetching users", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAvatarChange = (avatar) => {
    setNewUser({ ...newUser, avatar });
  };

  const handleChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/user', newUser);
      setUsers([...users, { ...newUser, id: response.data.userId }]);
      setShowUserModal(false);
    } catch (error) {
      console.error("Error adding user", error);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleConnectAsUser = async (userId) => {
    try {
      const response = await axios.post('/admin/admin-login-as-user', { userId });
      const { token, user } = response.data.result;

      // Save user details in local storage
      localStorage.setItem('avatar', user.avatar);
      localStorage.setItem('name', (user.name || '').split(' ')[0]);

      // Navigate to dashboard as the selected user
      navigate('/dashboard/simulations');
    } catch (error) {
      console.error("Error connecting as user", error);
    }
  };

  const handleVerifyUser = async (userId) => {
    try {
      await axios.put(`/user/verify/${userId}`);
      fetchUsers(); // Refresh users list
    } catch (error) {
      console.error("Error verifying user", error);
    }
  };

  const handleDisableUser = async (userId) => {
    try {
      await axios.put(`/user/disable/${userId}`);
      fetchUsers(); // Refresh users list
    } catch (error) {
      console.error("Error disabling user", error);
    }
  };

  const handleLogout = () => {
    axios.get('/auth/logout')
      .then(() => {
        localStorage.removeItem('avatar');
        localStorage.removeItem('name');
        navigate('/');
      })
      .catch(err => console.error("Error logging out", err));
  };

  return (
    <Container fluid className="align-items-center">
      <Row>
        <h1>Users</h1>
        <h5>Here you can manage your users.</h5>
        <Form inline className="mb-3">
          <Row>
            <Col>
              <FormControl
                type="text"
                placeholder="Search for a user"
                className="mr-sm-2"
                value={searchQuery}
                onChange={handleSearch}
              />
            </Col>
            <Col>
              <div className='d-flex p-1'>
                <Button variant="secondary" onClick={fetchUsers}>Search</Button>
                <Button variant="primary" onClick={() => setShowUserModal(true)}>Add User</Button>
                <CSVLink data={users} filename="users.csv">
                  <Button variant="info">Export to CSV</Button>
                </CSVLink>
              </div>
            </Col>
          </Row>
        </Form>
      </Row>
      <Row>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Email Verification</th>
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="7" className="text-center">
                  <Spinner animation="border" />
                </td>
              </tr>
            ) : (
              users.length > 0 ? users.map(user => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>{user.email_verified ? 'Verified' : 'Unverified'}</td>
                  <td>{user.active ? 'Active' : 'Inactive'}</td>
                  <td>
                    <Button variant="warning" onClick={() => handleDisableUser(user.id)}>Disable</Button>
                    <Button variant="success" onClick={() => handleVerifyUser(user.id)}>Verify</Button>
                    <Button variant="info" onClick={() => handleConnectAsUser(user.id)}>Connect as the user</Button>
                  </td>
                </tr>
              )) : (
                <tr>
                  <td colSpan="7" className="text-center">No users found</td>
                </tr>
              )
            )}
          </tbody>
        </Table>
        <Pagination>
          {[...Array(totalPages).keys()].map(pageNumber => (
            <Pagination.Item key={pageNumber + 1} active={pageNumber + 1 === page} onClick={() => handlePageChange(pageNumber + 1)}>
              {pageNumber + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </Row>
      <Modal show={showUserModal} onHide={() => setShowUserModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddUser}>
            {/* Add User Form Fields */}
            <Form.Group className="m-3">
              <Form.Label>Choose Avatar</Form.Label>
              <div className="d-flex justify-content-around">
                <div className="avatar-container" onClick={() => handleAvatarChange('avatar1')}>
                  <img
                    src={Avatar1}
                    alt="Avatar 1"
                    className={`avatar-img ${newUser.avatar === 'avatar1' ? 'selected' : ''}`}
                  />
                </div>
                <div className="avatar-container" onClick={() => handleAvatarChange('avatar2')}>
                  <img
                    src={Avatar2}
                    alt="Avatar 2"
                    className={`avatar-img ${newUser.avatar === 'avatar2' ? 'selected' : ''}`}
                  />
                </div>
              </div>
            </Form.Group>
            <Form.Group className="m-3">
              <Form.Label>Name</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="name"
                  value={newUser.name}
                  onChange={handleChange}
                  isInvalid={!!errors.name}
                  autoComplete="name"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="m-3">
              <Form.Label>Email</Form.Label>
              <InputGroup>
                <Form.Control
                  type="email"
                  name="email"
                  value={newUser.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                  autoComplete="email"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="m-3">
              <Form.Label>Country</Form.Label>
              <InputGroup>
                <Form.Control
                  as="select"
                  name="country"
                  value={newUser.country}
                  onChange={handleChange}
                  isInvalid={!!errors.country}
                  className="custom-select"
                >
                  <option value="">Choose...</option>
                  <option value="Algeria">Algeria</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Libya">Libya</option>
                  <option value="Other">Other</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.country}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="m-3">
              <Form.Label>Phone Number</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="phoneNumber"
                  value={newUser.phoneNumber}
                  onChange={handleChange}
                  isInvalid={!!errors.phoneNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phoneNumber}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="m-3 password-input-group">
              <Form.Label>Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type="password"
                  name="password"
                  value={newUser.password}
                  onChange={handleChange}
                  isInvalid={!!errors.password}
                  className="password-input"
                  autoComplete="current-password"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button variant="primary" type="submit" className="mt-4 w-100 mb-2 custom-button">
                Create User
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <nav>
        <Button variant="danger" onClick={handleLogout}>
          Logout
        </Button>
      </nav>
    </Container>
  );
};

export default Users;