import React from 'react';
import { Container, Row, Col, Alert, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const EmailVerification = () => {
  const navigate = useNavigate();

  return (
    <Container className="center-container">
      <Row className="justify-content-md-center">
        <Col md={10}>
          <div className="form-container">
            <h1 className="text-center">Verify Your Email</h1>
            <Alert variant='info'>
              A verification link has been sent to your email. Please click the link to verify your account.
            </Alert>
            <Button variant="primary" onClick={() => navigate('/')} className="w-100">
              Back to Login
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default EmailVerification;