import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Card, Form, Table, Button, Row, Col, Tabs, Tab, Modal } from 'react-bootstrap';
import EmployeeSalariesTable from './EmployeeSalariesTable';
import ToolsFeesTable from './ToolsFeesTable';
import FixedFeesTable from './FixedFeesTable';
import axios from '../utils/axios';
import { useTranslation } from 'react-i18next';

const FeeVariationDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [variation, setVariation] = useState({
    name: '',
    fixedFees: [],
    toolsFees: [],
    employeeSalaries: [],
    sumOfToolsFees: 0,
    sumOfFixedFees: 0,
    sumOfEmployeeSalaries: 0,
  });

  const fetchVariation = useCallback(async () => {
    try {
      const response = await axios.get(`/fee-variations/${id}`);
      setVariation({
        ...response.data,
        fixedFees: response.data.FixedFees || [],
        toolsFees: response.data.ToolFees || [],
        employeeSalaries: response.data.EmployeeSalaries || [],
        sumOfToolsFees: response.data.sumOfToolsFees || 0,
        sumOfFixedFees: response.data.sumOfFixedFees || 0,
        sumOfEmployeeSalaries: response.data.sumOfEmployeeSalaries || 0,
      });
    } catch (error) {
      console.error(t('FEE_VARIATIONS.ERROR_FETCHING'), error);
    }
  }, [id, t]);

  useEffect(() => {
    fetchVariation();
  }, [fetchVariation]);

  const sumOfToolsFees = useMemo(() => {
    const sum = variation.toolsFees.reduce((acc, fee) => {
      const amount = parseFloat(fee.amount_in_DZD) || 0; 
      switch (fee.frequency) {
        case 'Daily':
          return acc + amount * 30;
        case 'Monthly':
          return acc + amount;
        case 'Quarterly':
          return acc + amount / 3;
        case 'Yearly':
          return acc + amount / 12;
        default:
          return acc;
      }
    }, 0); 
    return sum.toFixed(2); 
  }, [variation.toolsFees]);

  const sumOfFixedFees = useMemo(() => {
    const sum = variation.fixedFees.reduce((acc, fee) => {
      const amount = parseFloat(fee.amount) || 0; 
      switch (fee.frequency) {
        case 'Daily':
          return acc + amount * 30;
        case 'Monthly':
          return acc + amount;
        case 'Quarterly':
          return acc + amount / 3;
        case 'Yearly':
          return acc + amount / 12;
        default:
          return acc;
      }
    }, 0); 
    return sum.toFixed(2); 
  }, [variation.fixedFees]);

  const sumOfEmployeeSalaries = useMemo(() => {
    return variation.employeeSalaries.reduce((sum, salary) => {
      const fixedAmount = parseFloat(salary?.fixed_amount) || 0; 
      return sum + fixedAmount;
    }, 0).toFixed(2); 
  }, [variation.employeeSalaries]);

  const handleSave = async () => {
    try {
      await axios.put(`/fee-variations/${id}`, variation);
      navigate('/dashboard/fees');
    } catch (error) {
      console.error(t('FEE_VARIATIONS.ERROR_ADDING'), error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = value === '' ? null : value;
    setVariation({ ...variation, [name]: newValue });
  };

  if (!variation) {
    return <div>{t('GENERAL.LOADING')}</div>;
  }

  return (
    <Container>
      <Button variant="secondary" onClick={() => navigate('/dashboard/fees')}>{t('GENERAL.BACK_TO_LIST')}</Button>
      <Button variant="primary" onClick={handleSave} style={{ float: 'right' }}>{t('GENERAL.SAVE')}</Button>

      <Card className="mt-3">
        <Card.Body>
          <h2>{variation.name}</h2>
          <Row className='mb-3'>
            <Col md={4}>
              <Form.Group controlId="variationName">
                <Form.Label>{t('FEE_VARIATIONS.NAME')}</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={variation.name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="scroll-container mb-3">
            <Row className="flex-nowrap">
              <Col md={3} lg={3} className="scroll-item">
                <Card className="sum-card mb-2 gradient-card">
                  <Card.Body>
                    <Card.Text className="text">
                      <div>{t('FEE_VARIATIONS.SUM_OF_EMPLOYEE_SALARIES')}</div>
                      <p>{sumOfEmployeeSalaries} DZD/Month</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} lg={3} className="scroll-item">
                <Card className="sum-card mb-2 gradient-card">
                  <Card.Body>
                    <Card.Text className="text">
                      <div>{t('FEE_VARIATIONS.SUM_OF_FIXED_FEES')}</div>
                      <p>{sumOfFixedFees} DZD/Month</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="scroll-item">
                <Card className="sum-card mb-2 gradient-card">
                  <Card.Body>
                    <Card.Text className="text">
                      <div>{t('FEE_VARIATIONS.SUM_OF_TOOLS_FEES')}</div>
                      <p>{sumOfToolsFees} DZD/Month</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>

      <Tabs defaultActiveKey="employeeSalaries" className="mt-3">
        <Tab eventKey="employeeSalaries" title={t('FEE_VARIATIONS.EMPLOYEE_SALARIES')}>
          <Row>
            <Col md={8}>
              <EmployeeSalariesTable fees={variation.employeeSalaries} setVariation={setVariation} variation={variation} allowEdit={true} />
            </Col>
          </Row>
        </Tab>

        <Tab eventKey="fixedFees" title={t('FEE_VARIATIONS.FIXED_FEES')}>
          <Row>
            <Col md={8}>
              <FixedFeesTable fees={variation.fixedFees} setVariation={setVariation} variation={variation} allowEdit={true} />
            </Col>
          </Row>
        </Tab>

        <Tab eventKey="toolsFees" title={t('FEE_VARIATIONS.TOOLS_FEES')}>
          <Row>
            <Col md={8}>
              <ToolsFeesTable fees={variation.toolsFees} setVariation={setVariation} variation={variation} allowEdit={true} />
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </Container>
  );
};

export default FeeVariationDetails;