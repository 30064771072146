import React from 'react';
import { Table } from 'react-bootstrap';

const formatNumberWithCurrency = (num, currency = 'DZD') => {
    const number = parseFloat(num);

    if (isNaN(number) || number === null) return `0.00 ${currency}`;
    return `${number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ').replace(/\.00$/, '')} ${currency}`;
};

const formatPercentage = (num) => {
    const number = parseFloat(num);

    if (isNaN(number) || number === null) return `0.00 %`;
    return `${number.toFixed(2)} %`;
};

const fields = {
    order: [
        { label: 'Product Price', key: 'product_price', format: formatNumberWithCurrency },
        { label: 'Sale Price', key: 'sale_price', format: formatNumberWithCurrency },
        { label: 'Delivery Price', key: 'delivery_price', format: formatNumberWithCurrency },
        { label: 'Packaging Fee', key: 'packaging_fee', format: formatNumberWithCurrency },
        { label: 'Confirmation Cost per order', key: 'confirmation_cost_per_order', format: formatNumberWithCurrency, isCost: true },
        { label: 'Delivery Cost per order', key: 'delivery_cost_per_order', format: formatNumberWithCurrency, isCost: true },
        { label: 'Return fee per order', key: 'return_fee_per_order', format: formatNumberWithCurrency },
        { label: 'COD fee per order', key: 'cod_fee_per_order', format: formatNumberWithCurrency },
        { label: 'Other fee per order', key: 'other_fee_per_order', format: formatNumberWithCurrency },
        { label: 'Employee fee per order', key: 'fee_role_per_order', format: formatNumberWithCurrency }
    ],
    daily: [
        { label: 'Spend On Ads', key: 'daily_ads_spend', format: num => formatNumberWithCurrency(num, '$') },
        { label: 'Confirmed Orders', key: 'confirmed_orders_daily', format: num => formatNumberWithCurrency(num, 'Orders') },
        { label: 'Delivered Orders', key: 'delivered_orders_daily', format: num => formatNumberWithCurrency(num, 'Orders') },
        { label: 'Return Fees', key: 'return_fee_daily', format: formatNumberWithCurrency },
        { label: 'COD Fee', key: 'cod_fee_daily', format: formatNumberWithCurrency },
        { label: 'Tools Fees', key: 'tools_fee_daily', format: formatNumberWithCurrency },
        { label: 'Fixed Fees', key: 'fixed_fee_daily', format: formatNumberWithCurrency },
        { label: 'Employees Salary', key: 'employee_salary_daily', format: formatNumberWithCurrency }
    ],
    monthly: [
        { label: 'Spend On Ads', key: 'monthly_ads_spend', format: num => formatNumberWithCurrency(num, '$') },
        { label: 'Confirmed Orders', key: 'confirmed_orders_monthly', format: num => formatNumberWithCurrency(num, 'Orders') },
        { label: 'Delivered Orders', key: 'delivered_orders_monthly', format: num => formatNumberWithCurrency(num, 'Orders') },
        { label: 'Return Fees', key: 'return_fee_monthly', format: formatNumberWithCurrency },
        { label: 'COD Fee', key: 'cod_fee_monthly', format: formatNumberWithCurrency },
        { label: 'Tools Fees', key: 'tools_fee_monthly', format: formatNumberWithCurrency, currency: '$' },
        { label: 'Fixed Fees', key: 'fixed_fee_monthly', format: formatNumberWithCurrency },
        { label: 'Employees Salary', key: 'employee_salary_monthly', format: formatNumberWithCurrency }
    ]
};

const highlightedFields = {
    order: [
        { label: 'Net Profit per Order', key: 'net_profit', format: formatNumberWithCurrency },
        { label: 'ROI', key: 'roi_per_order', format: num => formatNumberWithCurrency(num, '%')},
        { label: 'ROAS', key: 'roas_per_order', format: num => formatNumberWithCurrency(num, '')}
    ],
    daily: [
        { label: 'Net Profit Daily', key: 'net_profit_daily', format: formatNumberWithCurrency },
        { label: 'ROI', key: 'roi_per_order', format: num => formatNumberWithCurrency(num, '%')},
        { label: 'ROAS', key: 'roas_per_order', format: num => formatNumberWithCurrency(num, ' ')}

    ],
    monthly: [
        { label: 'Net Profit Monthly', key: 'net_profit_monthly', format: formatNumberWithCurrency },
        { label: 'ROI', key: 'roi_per_order', format: num => formatNumberWithCurrency(num, '%')},
        { label: 'ROAS', key: 'roas_per_order', format: num => formatNumberWithCurrency(num, ' ')}
    ]
};

const ComparisonTable = ({ simulation1, simulation2, activeTab }) => {
    const renderTableRows = () => {
        return fields[activeTab].map((field) => {
            const value1 = simulation1?.[field.key];
            const value2 = simulation2?.[field.key];
            const difference = value1 - value2;
            const percentageDifference = (difference / value1) * 100;

            const differenceColor = field.isCost ? (difference > 0 ? 'text-danger' : 'text-success') : (difference < 0 ? 'text-danger' : 'text-success');

            return (
                <tr key={field.key}>
                    <td className="td-label">{field.label}</td>
                    <td>{field.format(value1)}</td>
                    <td>{field.format(value2)}</td>
                    <td className={differenceColor}>{field.format(difference)}</td>
                    <td className={differenceColor}>{formatPercentage(percentageDifference)}</td>
                </tr>
            );
        });
    };

    return (
        <div>
            <Table responsive bordered striped hover className="custom-table mb-2">
                <thead>
                    <tr>
                        <th style={{ textAlign: 'left' }}>Label</th>
                        <th>{simulation1?.name}</th>
                        <th>{simulation2?.name}</th>
                        <th>Difference</th>
                        <th>Percentage Diff</th>
                    </tr>
                </thead>
                <tbody>{renderTableRows()}</tbody>
            </Table>
            <FooterComparisonTable simulation1={simulation1} simulation2={simulation2} activeTab={activeTab} />
        </div>
    );
};

const FooterComparisonTable = ({ simulation1, simulation2, activeTab }) => {
    const renderHighlightedRows = () => {
        return highlightedFields[activeTab].map((field) => {
            const value1 = simulation1?.[field.key];
            const value2 = simulation2?.[field.key];
            const difference = value1 - value2;
            const percentageDifference = (difference / value1) * 100;

            const differenceColor = field.isCost ? (difference > 0 ? 'text-danger' : 'text-success') : (difference < 0 ? 'text-danger' : 'text-success');

            return (
                <tr key={field.key}>
                    <td className="td-label">{field.label}</td>
                    <td>{field.format(value1)}</td>
                    <td>{field.format(value2)}</td>
                    <td className={differenceColor}>{field.format(difference)}</td>
                    <td className={differenceColor}>{formatPercentage(percentageDifference)}</td>
                </tr>
            );
        });
    };

    return (
        <Table responsive bordered striped hover className="custom-table-footer mb-4">
            <thead>
                <tr>
                    <th  style={{ textAlign: 'left' }}>Label</th>
                    <th>{simulation1?.name}</th>
                    <th>{simulation2?.name}</th>
                    <th>Difference</th>
                    <th>Percentage Diff</th>
                </tr>
            </thead>
            <tbody>{renderHighlightedRows()}</tbody>
        </Table>
    );
};

export default ComparisonTable;