// DashboardLayout.js
import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Nav, Navbar, Image, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCreditCard, faBars, faGlobe, faBell, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../assets/logo.png';
import './DashboardLayout.css';
import { LanguageContext } from '../contexts/LanguageContext';
import Avatar1 from '../assets/avatar1.jpg';
import Avatar2 from '../assets/avatar2.jpg';
import axios from '../utils/axios';
import { UserContext } from '../contexts/UserContext';

const languageLabels = {
  en: 'English',
  fr: 'Français',
  ar: 'العربية'
};

const AdminPanel = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { t } = useTranslation();
  const { language, changeLanguage } = useContext(LanguageContext);
  const navigate = useNavigate();
  const { user, updateUserContext } = useContext(UserContext);

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    document.dir = language === 'ar' ? 'rtl' : 'ltr';
  }, [language]);

  const handleLogout = async () => {
    try {
      await axios.get('/auth/logout');
      localStorage.removeItem('avatar');
      localStorage.removeItem('name');
      updateUserContext({ avatar: '', name: '' });
    } catch (error) {
      console.error('Error during logout:', error);
    }
    navigate('/');
  };

  const getAvatarSrc = () => {
    if (user.avatar === 'avatar1') return Avatar1;
    if (user.avatar === 'avatar2') return Avatar2;
    return Avatar1;
  };

  return (
    <Container fluid className='dashboard-layout'>
      <Row>
        <Col xs={12} className="header">
          <Navbar expand="lg" className="shadow-sm d-flex justify-content-between align-items-center">
            <Navbar.Brand as={NavLink} to="/dashboard" className='logo-font d-flex align-items-center '>
              <h5>Cod Admin Panel</h5>
            </Navbar.Brand>
            <div className="d-flex align-items-center">
              <Nav.Item>
                <FontAwesomeIcon icon={faBell} style={{ fontSize: '2rem', color: '#F8F9FA', margin: '0 1rem' }} />
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon 
                  icon={faBars} 
                  onClick={handleSidebarToggle}
                  className='sidebar-toggle d-lg-none d-md-none'
                />
              </Nav.Item>
              <div className='settings'>
                <Nav.Item>
                  <Nav.Link className="p-0">
                    <Dropdown className="me-3 custom-dropdown">
                      <Dropdown.Toggle variant="light" id="language-dropdown" className="custom-dropdown-toggle">
                        <FontAwesomeIcon icon={faGlobe} className="m-1" />
                        <span className="m-1">{languageLabels[language]}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {Object.keys(languageLabels).map((lang) => (
                          <Dropdown.Item key={lang} onClick={() => changeLanguage(lang)}>
                            {languageLabels[lang]}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="p-0">
                    <Dropdown className="me-3 custom-dropdown">
                      <Dropdown.Toggle variant="light" id="avatar-dropdown" className="custom-dropdown-toggle d-flex align-items-center">
                        <Image src={getAvatarSrc()} roundedCircle width="30" height="30" className="me-2" />
                        <span>{user.name}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item as={NavLink} to="/admin/profile">Profile</Dropdown.Item>
                        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Nav.Link>
                </Nav.Item>
              </div>
            </div>
          </Navbar>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs={12} lg={2} className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
          <Nav className="flex-column mt-4">
            <Nav.Item>
              <Nav.Link as={NavLink} to="/admin/users" onClick={() => setIsSidebarOpen(false)}>
                <FontAwesomeIcon icon={faUser} className="me-2" />Users
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={NavLink} to="/admin/payments" onClick={() => setIsSidebarOpen(false)}>
                <FontAwesomeIcon icon={faCreditCard} className="me-2" />Payments
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col xs={12} lg={10} className="page-content p-3">
          <Outlet />
        </Col>
      </Row>
      {isSidebarOpen && <div className="backdrop open" onClick={() => setIsSidebarOpen(false)} />}
    </Container>
  );
};

export default AdminPanel;