import React, { useState } from 'react';
import { Card, Button, Modal, Form } from 'react-bootstrap';
import axios from '../utils/axios';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ToastNotification from './ToastNotification';
import EmployeeCard from './EmployeeCard';

const EmployeeSalariesTable = ({ fees = [], setVariation, variation, allowEdit }) => {
  const { t } = useTranslation();
  const [modalShow, setModalShow] = useState(false);
  const [editingSalary, setEditingSalary] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/employee-salaries/${id}`);
      setVariation({
        ...variation,
        employeeSalaries: variation.employeeSalaries.filter(salary => salary.id !== id)
      });
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleAdd = () => {
    setEditingSalary({
      name: '', 
      role: 'Manager 1', 
      type: 'Full Time', 
      fixed_amount: '', 
      variable_amount: '', 
      calculation_basis: 'both', 
      fee_variation_id: variation.id, 
      per_order_basis: 'delivered'
    });
    setIsEditMode(false);
    setModalShow(true);
  };

  const handleEdit = (employee) => {
    setEditingSalary(employee);
    setIsEditMode(true);
    setModalShow(true);
  };

  const handleSave = async () => {
    if (validateForm()) {
      try {
        if (isEditMode) {
          const response = await axios.put(`/employee-salaries/${editingSalary.id}`, editingSalary);
          const updatedSalaries = fees.map(salary => salary.id === editingSalary.id ? response.data : salary);
          setVariation({ ...variation, employeeSalaries: updatedSalaries });
        } else {
          const response = await axios.post('/employee-salaries', editingSalary);
          setVariation({ ...variation, employeeSalaries: [...variation.employeeSalaries, response.data] });
        }
        setModalShow(false);
        setValidationErrors({});
      } catch (error) {
        setModalShow(false);
        handleErrors(error);
      }
    }
  };

  const handleErrors = (error) => {
    if (error.response) {
      if (error.response.status === 429) {
        setError(t('employeeSalariesTable.tooManyRequests'));
      } else if (error.response.status === 400) {
        setError(t('employeeSalariesTable.limitReached'));
      } else {
        setError(t('employeeSalariesTable.defaultError'));
      }
    } else {
      setError(t('employeeSalariesTable.defaultError'));
    }
  };

  const handleChange = (e, field) => {
    let value = e.target.value?.replace(/^0+(?=\d)/, ''); // Use optional chaining to prevent null references

    if (field === 'calculation_basis') {
      const newEditingSalary = { ...editingSalary, [field]: value };

      if (value === 'fixed') {
        newEditingSalary.variable_amount = 0;
        newEditingSalary.per_order_basis = '';
      } else if (value === 'variable') {
        newEditingSalary.fixed_amount = 0;
        newEditingSalary.per_order_basis = 'delivered';
      } else if (value === 'both') {
        newEditingSalary.per_order_basis = 'delivered';
      }
      setEditingSalary(newEditingSalary);
    } else {
      setEditingSalary({ ...editingSalary, [field]: value ?? '' });
    }

    setValidationErrors({});
  };

  const validateForm = () => {
    const errors = {};
    if (!editingSalary.name) errors.name = t('employeeSalariesTable.nameRequired');
    if (editingSalary.calculation_basis === 'fixed' && !editingSalary.fixed_amount) errors.fixed_amount = t('employeeSalariesTable.fixedAmountRequired');
    if (['variable', 'both'].includes(editingSalary.calculation_basis) && !editingSalary.variable_amount) errors.variable_amount = t('employeeSalariesTable.variableAmountRequired');
    if (['variable', 'both'].includes(editingSalary.calculation_basis) && !editingSalary.per_order_basis) errors.per_order_basis = t('employeeSalariesTable.perOrderBasisRequired');

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  return (
    <>
      {error && (
        <ToastNotification
          show={error}
          type="danger"
          onClose={() => setError(null)}
          dismissible
          message={error}
        />
      )}
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-end align-items-center mb-3">
            {allowEdit && (
              <Button variant="primary" onClick={handleAdd}>
                <FontAwesomeIcon icon={faPlus} /> {t('employeeSalariesTable.addEmployee')}
              </Button>
            )}
          </div>
          <div>
            {fees.length > 0 ? fees.map((employee, index) => (
              <EmployeeCard
                key={employee.id}
                employee={employee}
                onDelete={handleDelete}
                onEdit={handleEdit}
                allowEdit={allowEdit}
              />
            )) : (
              <div className="text-center">{t('employeeSalariesTable.noEmployeeSalaries')}</div>
            )}
          </div>
        </Card.Body>
      </Card>

      <Modal show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditMode ? t('employeeSalariesTable.editEmployeeSalary') : t('employeeSalariesTable.addEmployeeSalary')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="employeeName">
            <Form.Label>{t('employeeSalariesTable.name')}</Form.Label>
            <Form.Control
              type="text"
              value={editingSalary?.name || ''}
              onChange={(e) => handleChange(e, 'name')}
              isInvalid={!!validationErrors.name}
              className="form-control-custom"
            />
            <Form.Control.Feedback type="invalid">
              {validationErrors.name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="employeeRole" className="select-dropdown">
            <Form.Label>{t('employeeSalariesTable.role')}</Form.Label>
            <Form.Control
              as="select"
              value={editingSalary?.role || ''}
              onChange={(e) => handleChange(e, 'role')}
              className="form-control-custom"
            >
              <option value="Manager 1">{t('roles.manager_1')}</option>
              <option value="Manager 2">{t('roles.manager_2')}</option>
              <option value="Manager 3">{t('roles.manager_3')}</option>
              <option value="Confirmation Agent">{t('roles.confirmation_agent')}</option>
              <option value="Media Buyer Agent">{t('roles.media_buyer_agent')}</option>
              <option value="Packaging Agent">{t('roles.packaging_agent')}</option>
              <option value="Other">{t('roles.other')}</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="calculationBasis" className="select-dropdown">
            <Form.Label>{t('employeeSalariesTable.calculationBasis')}</Form.Label>
            <Form.Control
              as="select"
              value={editingSalary?.calculation_basis || 'both'}
              onChange={(e) => handleChange(e, 'calculation_basis')}
              className="form-control-custom"
            >
              <option value="fixed">{t('employeeSalariesTable.fixed')}</option>
              <option value="variable">{t('employeeSalariesTable.variable')}</option>
              <option value="both">{t('employeeSalariesTable.both')}</option>
            </Form.Control>
          </Form.Group>

          {['fixed', 'both'].includes(editingSalary?.calculation_basis) && (
            <Form.Group controlId="fixedAmount">
              <Form.Label>{t('employeeSalariesTable.fixedAmount')}</Form.Label>
              <Form.Control
                type="number"
                value={editingSalary?.fixed_amount || ''}
                onChange={(e) => handleChange(e, 'fixed_amount')}
                isInvalid={!!validationErrors.fixed_amount}
                className="form-control-custom"
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.fixed_amount}
              </Form.Control.Feedback>
            </Form.Group>
          )}

          {['variable', 'both'].includes(editingSalary?.calculation_basis) && (
            <>
              <Form.Group controlId="variableAmount">
                <Form.Label>{t('employeeSalariesTable.variableAmount')}</Form.Label>
                <Form.Control
                  type="number"
                  value={editingSalary?.variable_amount || ''}
                  onChange={(e) => handleChange(e, 'variable_amount')}
                  isInvalid={!!validationErrors.variable_amount}
                  className="form-control-custom"
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.variable_amount}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="per_order_basis">
                <Form.Label>{t('employeeSalariesTable.perOrderBasis')}</Form.Label>
                <div>
                  <Form.Check
                    type="radio"
                    label={t('employeeSalariesTable.perOrderConfirmed')}
                    name="per_order_basis"
                    value="confirmed"
                    checked={editingSalary?.per_order_basis === 'confirmed'}
                    onChange={(e) => handleChange(e, 'per_order_basis')}
                  />
                  <Form.Check
                    type="radio"
                    label={t('employeeSalariesTable.perOrderDelivered')}
                    name="per_order_basis"
                    value="delivered"
                    checked={editingSalary?.per_order_basis === 'delivered'}
                    onChange={(e) => handleChange(e, 'per_order_basis')}
                  />
                </div>
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>{t('employeeSalariesTable.cancel')}</Button>
          <Button variant="primary" onClick={handleSave}>
            {isEditMode ? t('employeeSalariesTable.saveChanges') : t('employeeSalariesTable.addEmployeeSalary')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EmployeeSalariesTable;