import React, { useState } from 'react';
import { Card, Button, Modal } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import avatar from '../assets/avayat.svg';
import './EmployeeCard.css'; // Create a CSS file for custom styling if needed

const EmployeeCard = ({ employee, onDelete, onEdit, allowEdit }) => {
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const handleDeleteConfirmation = () => {
    setDeleteModalShow(false);
    onDelete(employee.id);
  };

  return (
    <>
      <Card className="employee-card mb-2">
        <Card.Body className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div className="avatar-placeholder">
              <img src={avatar} alt="Avatar" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
            <div className="info ml-3">
              <Card.Title>{employee.name}</Card.Title>
              <div>{employee.role}</div>
              <div>
                {employee.calculation_basis === 'fixed' ? `Salary ${employee.fixed_amount} DZD / month` 
                : employee.calculation_basis === 'both' ? `Salary & Variable : ${employee.fixed_amount} DZD / month & ${employee.variable_amount} DZD` 
                : employee.calculation_basis === 'variable' ? `Variable ${employee.variable_amount} DZD` 
                : ''} 
                {employee.per_order_basis === "confirmed" ? (
                  <span className="custom-badge custom-badge-confirmed ms-2">Per Order Confirmed</span>
                ) : employee.per_order_basis === "delivered" ? (
                  <span className="custom-badge custom-badge-delivered ms-2">Per Order Delivered</span>
                ) : null}
              </div>
            </div>
          </div>
          {allowEdit && (
            <div className="action-buttons">
              <Button variant="link" onClick={() => setDeleteModalShow(true)} className="btn-icon">
                <FaTrash className="action-icon-delete" />
              </Button>
              <Button variant="primary" onClick={() => onEdit(employee)} className="btn-custom">
                Edit
              </Button>
            </div>
          )}
        </Card.Body>
      </Card>
      
      <Modal show={deleteModalShow} onHide={() => setDeleteModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the employee "{employee.name}"?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteModalShow(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirmation}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EmployeeCard;