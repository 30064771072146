import React, { useState, useEffect, useContext } from 'react';
import { Container, Form, InputGroup, Button, Row, Col, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from '../utils/axios';
import './Profile.css';
import Avatar1 from '../assets/avatar1.jpg';
import Avatar2 from '../assets/avatar2.jpg';
import { UserContext } from '../contexts/UserContext';

const Profile = () => {
  const { t } = useTranslation();
  const { userContext, updateUserContext } = useContext(UserContext);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    country: '',
    phoneNumber: '',
    avatar: 'avatar1',
  });

  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [message, setMessage] = useState(null);
  const [passwordMessage, setPasswordMessage] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('/user/', { withCredentials: true });
        const userData = response.data;
        setUser(userData);
        setFormData({
          name: userData.name || '',
          email: userData.email || '',
          country: userData.country || '',
          phoneNumber: userData.phoneNumber || '',
          avatar: userData.avatar || 'avatar1',
        });
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };
    fetchUserData();
  }, []);

  const handleProfileChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePasswordChange = (e) => {
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAvatarChange = (avatar) => {
    setFormData({ ...formData, avatar });
  };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();

    // Check for empty or undefined form fields
    for (const key in formData) {
      if (formData[key] === undefined || formData[key] === '') {
        setMessage({ type: 'danger', text: 'Please fill out all fields.' });
        return;
      }
    }

    try {
      await axios.put(`/user/profile/`, formData, { withCredentials: true });

      setUser({ ...user, ...formData });
      const [firstName] = (formData.name || '').split(' ');
      updateUserContext({ avatar: formData.avatar, name: firstName }); // Update avatar in UserContext
      setMessage({ type: 'success', text: "Profile updated successfully!" });
    } catch (error) {
      console.error("Error updating profile:", error);
      setMessage({ type: 'danger', text: "Error updating profile." });
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setPasswordMessage({ type: 'danger', text: "Passwords do not match!" });
      return;
    }

    try {
      await axios.put(`/user/change-password/`, passwordData, { withCredentials: true });

      setPasswordMessage({ type: 'success', text: "Password changed successfully!" });
      setPasswordData({ currentPassword: '', newPassword: '', confirmPassword: '' });
    } catch (error) {
      setPasswordMessage({ type: 'danger', text: "Error changing password." });
      console.error("Error changing password:", error);
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="profile-container mt-4">
      <h2>{t('PROFILE.TITLE')}</h2>
      {message && (
        <Alert variant={message.type} onClose={() => setMessage(null)} dismissible>
          {message.text}
        </Alert>
      )}
      <Form onSubmit={handleProfileSubmit}>
        <Row className="mt-3">
          <Col md={12}>
            <Form.Label>{t('PROFILE.CHOOSE_AVATAR')}</Form.Label>
            <div className="d-flex justify-content-around">
              <div className="avatar-container" onClick={() => handleAvatarChange('avatar1')}>
                <img
                  src={Avatar1}
                  alt="Avatar 1"
                  className={`avatar-img ${formData.avatar === 'avatar1' ? 'selected' : ''}`}
                />
              </div>
              <div className="avatar-container" onClick={() => handleAvatarChange('avatar2')}>
                <img
                  src={Avatar2}
                  alt="Avatar 2"
                  className={`avatar-img ${formData.avatar === 'avatar2' ? 'selected' : ''}`}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>{t('PROFILE.NAME')}</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleProfileChange}
                  required
                  className='form-control-custom'
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="email" className="mb-3">
              <Form.Label>{t('PROFILE.EMAIL')}</Form.Label>
              <InputGroup>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  disabled
                  className='form-control-custom'
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="country" className="mb-3">
              <Form.Label>{t('PROFILE.COUNTRY')}</Form.Label>
              <InputGroup>
                <Form.Control
                  as="select"
                  name="country"
                  value={formData.country}
                  onChange={handleProfileChange}
                  className='custom-select'
                  required
                >
                  <option value="">Choose...</option>
                  <option value="Algeria">Algeria</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Libya">Libya</option>
                  <option value="Other">Other</option>
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="phoneNumber" className="mb-3">
              <Form.Label>{t('PROFILE.PHONE_NUMBER')}</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleProfileChange}
                  required
                  className='form-control-custom'
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <div className="d-flex justify-content-end">
          <Button className="mt-4" type="submit" variant="primary">{t('PROFILE.SAVE_CHANGES')}</Button>
        </div>
      </Form>

      <h2 className="mt-5">{t('PROFILE.CHANGE_PASSWORD_TITLE')}</h2>
      {passwordMessage && (
        <Alert variant={passwordMessage.type} onClose={() => setPasswordMessage(null)} dismissible>
          {passwordMessage.text}
        </Alert>
      )}
      <Form onSubmit={handlePasswordSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="currentPassword" className="mb-3">
              <Form.Label>{t('PROFILE.CURRENT_PASSWORD')}</Form.Label>
              <InputGroup>
                <Form.Control
                  type="password"
                  name="currentPassword"
                  value={passwordData.currentPassword}
                  onChange={handlePasswordChange}
                  className='form-control-custom'
                  required
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="newPassword" className="mb-3">
              <Form.Label>{t('PROFILE.NEW_PASSWORD')}</Form.Label>
              <InputGroup>
                <Form.Control
                  type="password"
                  name="newPassword"
                  value={passwordData.newPassword}
                  onChange={handlePasswordChange}
                  className='form-control-custom'
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="confirmPassword" className="mb-3">
              <Form.Label>{t('PROFILE.CONFIRM_PASSWORD')}</Form.Label>
              <InputGroup>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  value={passwordData.confirmPassword}
                  onChange={handlePasswordChange}
                  className='form-control-custom'
                  required
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Button type="submit" variant="primary">{t('PROFILE.CHANGE_PASSWORD_BUTTON')}</Button>
        </div>
      </Form>
    </Container>
  );
};

export default Profile;