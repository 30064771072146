import React, { useState } from 'react';
import { Card, Button, Table, Modal, Form } from 'react-bootstrap';
import axios from '../utils/axios';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import ToastNotification from './ToastNotification';

const FixedFeesTable = ({ fees = [], setVariation, variation, allowEdit }) => {
  const { t } = useTranslation();
  const [modalShow, setModalShow] = useState(false);
  const [editingFee, setEditingFee] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [error, setError] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [feeToDelete, setFeeToDelete] = useState(null);

  const handleDelete = async () => {
    try {
      await axios.delete(`/fixed-fees/${feeToDelete.id}`);
      setVariation({
        ...variation,
        fixedFees: variation.fixedFees.filter((fee) => fee.id !== feeToDelete.id),
      });
      setDeleteModalShow(false);
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleAdd = () => {
    setEditingFee({ name: '', amount: 0, frequency: 'Monthly', fee_variation_id: variation.id });
    setIsEditMode(false);
    setModalShow(true);
  };

  const handleEdit = (index) => {
    setEditingFee({ ...fees[index] });
    setIsEditMode(true);
    setModalShow(true);
  };

  const handleSave = async () => {
    try {
      if (isEditMode) {
        const response = await axios.put(`/fixed-fees/${editingFee.id}`, editingFee);
        const updatedFees = fees.map((fee) =>
          fee.id === editingFee.id ? response.data : fee
        );
        setVariation({ ...variation, fixedFees: updatedFees });
      } else {
        const response = await axios.post('/fixed-fees', editingFee);
        setVariation({ ...variation, fixedFees: [...variation.fixedFees, response.data] });
      }
      setModalShow(false);
    } catch (error) {
      setModalShow(false);
      handleErrors(error);
    }
  };

  const handleErrors = (error) => {
    if (error.response) {
      if (error.response.status === 429) {
        setError(t('fixedFeesTable.tooManyRequests'));
      } else if (error.response.status === 400) {
        setError(t('fixedFeesTable.limitReached'));
      } else {
        setError(t('fixedFeesTable.defaultError'));
      }
    } else {
      setError(t('fixedFeesTable.defaultError'));
    }
  };

  const handleChange = (e, name) => {
    let newValue = e.target.value;
    if (name === 'amount') {
      newValue = newValue?.replace(/^0+(?=\d)/, '');
    }
    setEditingFee({ ...editingFee, [name]: newValue !== null ? newValue : '' });
  };

  const handleShowDeleteModal = (fee) => {
    setFeeToDelete(fee);
    setDeleteModalShow(true);
  };

  return (
    <>
      {error && (
        <ToastNotification
          show={error}
          type="danger"
          onClose={() => setError(null)}
          dismissible
          message={error}
        ></ToastNotification>
      )}
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-end align-items-center mb-3">
            {allowEdit && (
              <Button variant="primary" onClick={handleAdd}>
                <FontAwesomeIcon icon={faPlus} /> {t('fixedFeesTable.addFixedFee')}
              </Button>
            )}
          </div>
          <Table bordered responsive striped hover className="custom-table">
            <thead>
              <tr>
                <th className="name-th">{t('fixedFeesTable.name')}</th>
                <th>{t('fixedFeesTable.amount')}</th>
                <th>{t('fixedFeesTable.frequency')}</th>
                {allowEdit && <th className="action">{t('fixedFeesTable.actions')}</th>}
              </tr>
            </thead>
            <tbody>
              {fees.length > 0 ? (
                fees.map((fee, index) => (
                  <tr key={fee.id}>
                    <td className="name">{fee.name}</td>
                    <td>{fee.amount} DZD</td>
                    <td>
                      {(() => {
                        switch (fee.frequency) {
                          case 'Daily':
                            return <span className="custom-badge custom-badge-daily">{fee.frequency}</span>;
                          case 'Yearly':
                            return <span className="custom-badge custom-badge-yearly">{fee.frequency}</span>;
                          case 'Monthly':
                            return <span className="custom-badge custom-badge-monthly">{fee.frequency}</span>;
                          case 'Quarterly':
                            return <span className="custom-badge custom-badge-quarterly">{fee.frequency}</span>;
                          default:
                            return <td>{fee.frequency}</td>;
                        }
                      })()}
                    </td>
                    {allowEdit && (
                      <td className="action-icons">
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="action-icon edit-icon"
                          onClick={() => handleEdit(index)}
                        />
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="action-icon delete-icon"
                          onClick={() => handleShowDeleteModal(fee)}
                        />
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={allowEdit ? "4" : "3"} className="text-center">
                    {t('fixedFeesTable.noFixedFees')}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditMode ? t('fixedFeesTable.editFixedFeeModal') : t('fixedFeesTable.addFixedFeeModal')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="fixedFeeName">
            <Form.Label>{t('fixedFeesTable.name')}</Form.Label>
            <Form.Control
              type="text"
              value={editingFee?.name || ''}
              onChange={(e) => handleChange(e, 'name')}
            />
          </Form.Group>
          <Form.Group controlId="fixedFeeAmount">
            <Form.Label>{t('fixedFeesTable.amount')}</Form.Label>
            <Form.Control
              type="number"
              value={editingFee?.amount || 0}
              onChange={(e) => handleChange(e, 'amount')}
            />
          </Form.Group>
          <Form.Group controlId="fixedFeeFrequency" className='select-dropdown'>
            <Form.Label>{t('fixedFeesTable.frequency')}</Form.Label>
            <Form.Control
              as="select"
              value={editingFee?.frequency || 'Monthly'}
              onChange={(e) => handleChange(e, 'frequency')}
            >
              <option value="Monthly">{t('frequencies.monthly')}</option>
              <option value="Yearly">{t('frequencies.yearly')}</option>
              <option value="Quarterly">{t('frequencies.quarterly')}</option>
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            {t('fixedFeesTable.cancel')}
          </Button>
          <Button variant="primary" onClick={handleSave}>
            {isEditMode ? t('fixedFeesTable.saveChanges') : t('fixedFeesTable.addFixedFee')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={deleteModalShow} onHide={() => setDeleteModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('fixedFeesTable.confirmDeleteTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('fixedFeesTable.confirmDeleteMessage', { name: feeToDelete?.name })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteModalShow(false)}>
            {t('fixedFeesTable.cancel')}
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            {t('fixedFeesTable.delete')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FixedFeesTable;