import React, { useState } from 'react';
import { Card, Button, Table, Modal, Row, Col, Form } from 'react-bootstrap';
import axios from '../utils/axios';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import ToastNotification from './ToastNotification';

const ToolsFeesTable = ({ fees = [], setVariation, variation, allowEdit }) => {
    const { t } = useTranslation();
    const [modalShow, setModalShow] = useState(false);
    const [editingFee, setEditingFee] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [error, setError] = useState(null);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [feeToDelete, setFeeToDelete] = useState(null);
    const [validationError, setValidationError] = useState('');
    const [amountInDZDPreview, setAmountInDZDPreview] = useState(variation.amount_in_DZD);

    const handleDelete = async () => {
        try {
            await axios.delete(`/tool-fees/${feeToDelete.id}`);
            setVariation({
                ...variation,
                toolsFees: variation.toolsFees.filter((fee) => fee.id !== feeToDelete.id),
            });
            setDeleteModalShow(false);
        } catch (error) {
            handleErrors(error);
        }
    };

    const handleAdd = () => {
        setEditingFee({ name: '', amount: 0, frequency: 'Monthly', currency: 'USD', currency_exchange: 1, fee_variation_id: variation.id });
        setIsEditMode(false);
        setModalShow(true);
    };

    const handleEdit = (index) => {
        setEditingFee({ ...fees[index] });
        setAmountInDZDPreview(fees[index].amount_in_DZD);
        setIsEditMode(true);
        setModalShow(true);
    };

    const handleSave = async () => {
        if (editingFee.currency_exchange <= 0) {
            setValidationError(t('toolsFeesTable.invalidCurrencyExchange'));
            return;
        }
        try {
            if (isEditMode) {
                const response = await axios.put(`/tool-fees/${editingFee.id}`, editingFee);
                const updatedFees = fees.map((fee) =>
                    fee.id === editingFee.id ? response.data : fee
                );
                setVariation({ ...variation, toolsFees: updatedFees });
            } else {
                const response = await axios.post('/tool-fees', editingFee);
                setVariation({ ...variation, toolsFees: [...variation.toolsFees, response.data] });
            }
            setModalShow(false);
        } catch (error) {
            setModalShow(false);
            handleErrors(error);
        }
    };

    const handleErrors = (error) => {
        if (error.response) {
            if (error.response.status === 429) {
                setError(t('toolsFeesTable.tooManyRequests'));
            } else if (error.response.status === 400) {
                setError(t('toolsFeesTable.limitReached'));
            } else {
                setError(t('toolsFeesTable.defaultError'));
            }
        } else {
            setError(t('toolsFeesTable.defaultError'));
        }
    };

    const handleChange = (e, field) => {
        let newValue = e.target.value === '' ? null : e.target.value;

        if (field === 'amount' || field === 'currency_exchange') {
            newValue = newValue?.replace(/^0+(?=\d)/, ''); // Ensure safe access to `replace`
        }

        let updatedFee;
        if (field === 'currency') {
            // If the currency changes, we don't need to parse it to float; keep it as a string.
            updatedFee = { ...editingFee, [field]: newValue, currency_exchange: newValue === 'DZD' ? 1 : editingFee.currency_exchange };
        } else {
            updatedFee = { ...editingFee, [field]: parseFloat(newValue) || 0 }; // Ensure updated values are numbers or 0
        }
        
        updatedFee = { ...editingFee, [field]: newValue }; 
        setEditingFee(updatedFee);
        calculateAmountInDZD(updatedFee);
    };

    const calculateAmountInDZD = (fee) => {
        let amountInDZD;
        if (fee.currency === 'DZD') {
            amountInDZD = parseFloat(fee.amount) || 0;
            fee.currency_exchange = 1;
        } else {
            amountInDZD = (parseFloat(fee.amount) || 0) * (parseFloat(fee.currency_exchange) || 1);
        }
        setAmountInDZDPreview(amountInDZD.toFixed(2)); // Format to 2 decimal places
    };

    const handleShowDeleteModal = (fee) => {
        setFeeToDelete(fee);
        setDeleteModalShow(true);
    };

    return (
        <>
            {error && (
                <ToastNotification
                    show={error}
                    type="danger"
                    onClose={() => setError(null)}
                    dismissible
                    message={error}
                />
            )}
            <Card>
                <Card.Body>
                    <div className="d-flex justify-content-end align-items-center mb-3">
                        {allowEdit && (
                            <Button variant="primary" onClick={handleAdd}>
                                <FontAwesomeIcon icon={faPlus} /> {t('toolsFeesTable.addToolFee')}
                            </Button>
                        )}
                    </div>
                    <Table bordered responsive striped hover className="custom-table">
                        <thead>
                            <tr>
                                <th className="name-th">{t('toolsFeesTable.name')}</th>
                                <th>{t('toolsFeesTable.amount')}</th>
                                <th>{t('toolsFeesTable.exchangeRate')}</th>
                                <th>{t('toolsFeesTable.amountInDZD')}</th>
                                <th>{t('toolsFeesTable.frequency')}</th>
                                {allowEdit && <th className="action">{t('toolsFeesTable.actions')}</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {fees.length > 0 ? (
                                fees.map((fee, index) => (
                                    <tr key={fee.id}>
                                        <td className="name">{fee.name}</td>
                                        <td>{fee.amount} {fee.currency}</td>
                                        <td>{fee.currency_exchange} DZD</td>
                                        <td>{fee.amount_in_DZD} DZD</td>
                                        <td>
                                            {(() => {
                                                switch (fee.frequency) {
                                                    case 'Daily':
                                                        return <span className="custom-badge custom-badge-daily">{fee.frequency}</span>;
                                                    case 'Yearly':
                                                        return <span className="custom-badge custom-badge-yearly">{fee.frequency}</span>;
                                                    case 'Monthly':
                                                        return <span className="custom-badge custom-badge-monthly">{fee.frequency}</span>;
                                                    case 'Quarterly':
                                                        return <span className="custom-badge custom-badge-quarterly">{fee.frequency}</span>;
                                                    default:
                                                        return <td>{fee.frequency}</td>;
                                                }
                                            })()}
                                        </td>
                                        {allowEdit && (
                                            <td className="action-icons">
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    className="action-icon edit-icon"
                                                    onClick={() => handleEdit(index)}
                                                />
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    className="action-icon delete-icon"
                                                    onClick={() => handleShowDeleteModal(fee)}
                                                />
                                            </td>
                                        )}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={allowEdit ? "4" : "3"} className="text-center">
                                        {t('toolsFeesTable.noToolFees')}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <Modal show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditMode ? t('toolsFeesTable.editToolFeeModal') : t('toolsFeesTable.addToolFeeModal')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="toolFeeName">
                        <Form.Label>{t('toolsFeesTable.name')}</Form.Label>
                        <Form.Control
                            type="text"
                            value={editingFee?.name || ''}
                            onChange={(e) => handleChange(e, 'name')}
                            className='form-control-custom'
                        />
                    </Form.Group>
                    <Row>
                     <Col>
                    <Form.Group controlId="toolFeeAmount">
                        <Form.Label>{t('toolsFeesTable.amount')}</Form.Label>
                        <Form.Control
                            type="number"
                            value={editingFee?.amount || 0}
                            onChange={(e) => handleChange(e, 'amount')}
                        />
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group controlId="toolFeeCurrency"  className='select-dropdown'>
                        <Form.Label>{t('toolsFeesTable.currency')}</Form.Label>
                        <Form.Control
                            as="select"
                            value={editingFee?.currency || 'USD'}
                            onChange={(e) => handleChange(e, 'currency')}
                        >
                            <option value="DZD">DZD</option>
                            <option value="USD">USD</option>
                            <option value="EUR">EUR</option>
                        </Form.Control>
                    </Form.Group>
                    </Col>
                    </Row>
                    {editingFee?.currency === 'USD' || editingFee?.currency === 'EUR' ? (
                        <Form.Group controlId="toolFeeCurrencyExchange">
                            <Form.Label>{t('toolsFeesTable.exchangeRate')}</Form.Label>
                            <Form.Control
                                type="number"
                                value={editingFee?.currency_exchange || 1}
                                onChange={(e) => handleChange(e, 'currency_exchange')}
                                className='form-control-custom'
                            />
                            <Form.Text className="text-muted">
                                {t('toolsFeesTable.exchangeRateDescription')}
                            </Form.Text>
                        </Form.Group>
                    ) : null}
                    <Form.Group controlId="amountInDZDPreview" className='mt-4'>
                        <Form.Label>{t('toolsFeesTable.amountInDZD')}</Form.Label>
                        <Form.Control
                            type="text"
                            readOnly
                            value={`${amountInDZDPreview} DZD`}
                            disabled
                            className='form-control-custom'
                        />
                    </Form.Group>
                    <Form.Group controlId="toolFeeFrequency" className='select-dropdown'>
                        <Form.Label>{t('toolsFeesTable.frequency')}</Form.Label>
                        <Form.Control
                            as="select"
                            value={editingFee?.frequency || 'Monthly'}
                            onChange={(e) => handleChange(e, 'frequency')}
                        >
                            <option value="Monthly">{t('frequencies.monthly')}</option>
                            <option value="Yearly">{t('frequencies.yearly')}</option>
                            <option value="Quarterly">{t('frequencies.quarterly')}</option>
                        </Form.Control>
                    </Form.Group>
                    {validationError && <div className="text-danger">{validationError}</div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalShow(false)}>
                        {t('toolsFeesTable.cancel')}
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        {isEditMode ? t('toolsFeesTable.saveChanges') : t('toolsFeesTable.addToolFee')}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={deleteModalShow} onHide={() => setDeleteModalShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('toolsFeesTable.confirmDeleteTitle')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t('toolsFeesTable.confirmDeleteMessage', { name: feeToDelete?.name })}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeleteModalShow(false)}>
                        {t('toolsFeesTable.cancel')}
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        {t('toolsFeesTable.delete')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ToolsFeesTable;