import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import './ToastNotification.css'; // Assuming you're using CSS for toast styles

const ToastNotification = ({ show, onClose, message, type }) => {
  const toastClass = type === 'success' ? 'toast-success' : 'toast-error';

  return (
    <ToastContainer className="toast-container">
      <Toast show={show} onClose={onClose} delay={3000} autohide className={toastClass}>
        <Toast.Body className="d-flex justify-content-between align-items-center">
          <div>{message}</div>
          <button type="button" className="ml-2 mb-1 close" onClick={onClose}>
            <span>&times;</span>
          </button>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ToastNotification;